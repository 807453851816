import { DataGrid, getGridStringOperators } from '@mui/x-data-grid';
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  Skeleton,
  TextField,
  Tooltip,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import * as XLSX from 'xlsx';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { saveAs } from 'file-saver';

import AddTarget from '../../../components/Modals/AddTarget';
import CustomHeader from '../../../components/common/header/CustomHeader';
import { useLocation } from 'react-router-dom';
import Sidebar from '../../../components/common/sidebar/Sidebar';
import eventBus from '../../../components/event-emitter/EventBus';

const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  width: 100%;
  ${isMobile && `position: relative;`}
`;

const TableContainer = styled.div`
  width: 100%;
  border-radius: 20px;
`;

const DataGridContainer = styled.div`
  max-width: 100%;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  display: ${isMobile ? 'flex' : 'grid'};
  border-radius: 20px;
  ${isMobile && `flex-direction: column;`}
`;

const Container = styled.div`
  width: 100%;
`;

const ViewTarget = () => {
  const location = useLocation();
  const stateValue = location.state;

  const fileInputRef = useRef(null);

  const [data, setData] = useState([]);
  const [targetData, setTargetData] = useState({});
  const [targetActivityData, setTargetActivityData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const [adding, setAdding] = React.useState();

  const [payLoad, setPayLoad] = useState({
    pageNumber: 1,
    State__c: '',
    District__c: '',
    Tehsil__c: '',
  });
  const [searchField, setSearchField] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationModel, setPaginationModel] = useState({
    page: currentPage - 1,
    pageSize: 20,
  });
  const [rowCount, setRowCount] = React.useState();
  const [addModal, setAddModal] = useState(false);
  const [viewOnly, setViewOnly] = useState(false);

  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState('');

  const [districts, setDistricts] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState('');

  const [tehsils, setTehsils] = useState([]);
  const [selectedTehsil, setSelectedTehsil] = useState('');

  const [selectedTerritory, setSelectedTerritory] = useState('');

  const [selectedStateEnabled, setSelectedStateEnabled] = useState(false);
  const [selectedDistrictEnabled, setSelectedDistrictEnabled] = useState(false);

  const [stateLoading, setStateLoading] = React.useState(false);
  const [districtLoading, setDistrictLoading] = React.useState(false);
  const [tehsilLoading, setTehsilLoading] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (stateValue) {
      setAddModal(true);
    } else {
      setAddModal(false);
    }
  }, []);

  React.useEffect(() => {
    const user = JSON.parse(localStorage.getItem('storedUserData'));
    if (user) {
      getTargetActivityData();
    } else {
      window.location.reload();
    }
  }, [
    payLoad.State__c,
    payLoad.District__c,
    payLoad.Tehsil__c,
    paginationModel.page,
  ]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getTargetActivityData = async () => {
    try {
      const payload = { ...payLoad, pageNumber: paginationModel.page + 1 };
      const arrOfTargetActivityData = [];
      const response = await window.Platform.database.getTargetActivityData(
        payload
      );
      setTargetActivityData(response.data);
      setRowCount(response.totalCount);

      const rows = response.data.map((item) => ({
        id: item.heroku_id__c,
        ...item,
      }));

      rows.map((item) => {
        const csvObj = {
          State: item.state_name__c,
          'Staff Name': item.staff_name__c,
          'Staff Profile': item.staff_profile__c,
          'Type Of Activity': item.type_of_activity__c,
          Product: item.product_name__c,
          Crop: item.crop_name__c,
          Year: item.year__c,
          Quarter: item?.quater__c?.split(';')?.join(','),
        };

        arrOfTargetActivityData.push(csvObj);
      });

      setCsvData(arrOfTargetActivityData);

      setData(rows);
      if (response.hasError) {
        window.NotificationUtils.showError(
          'Not able to get target activity data. Please try after some time.'
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getStates = async (data = '') => {
    try {
      setStateLoading(true);
      const response = await window.Platform.database.getAllStates({
        pageNumber: 1,
        searchField: data,
      });
      setStates(response.data);
      if (response.hasError) {
        window.NotificationUtils.showError(
          'Not able to get states. Please try after some time.'
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setStateLoading(false);
    }
  };

  const getDistricts = async (data = null, search = '') => {
    try {
      setDistrictLoading(true);
      const response = await window.Platform.database.getAllDistricts({
        stateSfid: data?.sfid || selectedState.sfid,
        searchField: search,
        pageNumber: 1,
      });
      setDistricts(response.data);
      if (response.hasError) {
        window.NotificationUtils.showError(
          'Not able to get districts. Please try after some time.'
        );
      }
      setSelectedStateEnabled(true);
    } catch (error) {
      console.error(error);
      setSelectedStateEnabled(false);
    } finally {
      setDistrictLoading(false);
    }
  };

  const getTehsils = async (data = null, search = '') => {
    try {
      setTehsilLoading(true);
      const response = await window.Platform.database.getAllTehsils({
        districtSfid: data?.sfid || selectedDistrict.sfid,
        searchField: search,
        pageNumber: 1,
      });
      setTehsils(response.data);
      if (response.hasError) {
        window.NotificationUtils.showError(
          'Not able to get tehsils. Please try after some time.'
        );
      }
      setSelectedDistrictEnabled(true);
    } catch (error) {
      console.error(error);
      setSelectedDistrictEnabled(true);
    } finally {
      setTehsilLoading(false);
    }
  };

  const getTerritory = async (data, condition = true) => {
    try {
      const response =
        await window.Platform.database.getTerritoryFromSubDistrict({
          subDistrictSfid: data?.sfid,
        });
      if (condition) {
        setSelectedTerritory(response.data);
      }
      if (response.hasError) {
        window.NotificationUtils.showError(
          'Not able to get territory. Please try after some time.'
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleStateChange = (data) => {
    setPayLoad((prev) => {
      return { ...prev, State__c: data?.sfid ? data?.sfid : '' };
    });
    setSelectedState(data);
    setSelectedDistrict(null);
    setSelectedTehsil(null);
    setSelectedTerritory(null);
    setSelectedStateEnabled(false);
    setSelectedDistrictEnabled(false);
    if (data) {
      getDistricts(data);
    }
  };

  const handleDistrictChange = (data) => {
    setPayLoad((prev) => {
      return { ...prev, District__c: data?.sfid ? data?.sfid : '' };
    });
    setSelectedDistrict(data);
    setSelectedTehsil(null);
    setSelectedTerritory(null);
    setSelectedDistrictEnabled(false);
    if (data) {
      getTehsils(data);
    }
  };

  const handleTehsilChange = (data) => {
    setPayLoad((prev) => {
      return { ...prev, Tehsil__c: data?.sfid ? data?.sfid : '' };
    });
    setSelectedTehsil(data);
    setSelectedTerritory(null);
    if (data) {
      getTerritory(data);
    }
  };

  const handleAdd = () => {
    setAddModal(true);
    setTargetData({});
    setAdding(true);
    setViewOnly(false);
  };

  const handleEdit = async (data) => {
    setAdding(false);
    setAddModal(true);

    const state = {
      name__c: data.state_name__c,
      sfid: data.state__c,
    };

    const district = {
      name__c: data.district_name__c,
      sfid: data.district__c,
    };

    const tehsil = {
      name__c: data.tehsil_name__c,
      sfid: data.tehsil__c,
    };

    const territory = {
      name: data.territory_mapping_name__c,
      sfid: data.territory_mapping__c,
    };

    const product = {
      product__c: data.product__c,
      crop_name__c: data.crop_name__c,
      crop__c: data.crop__c,
      product_name__c: data.product_name__c,
    };

    const crop = {
      product__c: data.product__c,
      crop_name__c: data.crop_name__c,
      crop__c: data.crop__c,
      product_name__c: data.product_name__c,
    };

    const targetFunction = {
      value: data.function__c,
      label: data.function__c,
    };

    const staff = {
      name__c: data.staff_name__c,
      sfid: data.staff_id__c,
      profile__c: data.staff_profile__c,
    };

    const modData = {
      ...data,
      state,
      district,
      tehsil,
      territory,
      product,
      crop,
      targetFunction,
      staff,
    };

    setTargetData(modData);
    setViewOnly(false);
  };

  const handleView = async (data) => {
    setAdding(false);
    setAddModal(true);

    const state = {
      name__c: data.state_name__c,
      sfid: data.state__c,
    };

    const district = {
      name__c: data.district_name__c,
      sfid: data.district__c,
    };

    const tehsil = {
      name__c: data.tehsil_name__c,
      sfid: data.tehsil__c,
    };

    const territory = {
      name: data.territory_mapping_name__c,
      sfid: data.territory_mapping__c,
    };

    const product = {
      product__c: data.product__c,
      crop_name__c: data.crop_name__c,
      crop__c: data.crop__c,
      product_name__c: data.product_name__c,
    };

    const crop = {
      product__c: data.product__c,
      crop_name__c: data.crop_name__c,
      crop__c: data.crop__c,
      product_name__c: data.product_name__c,
    };

    const targetFunction = {
      value: data.function__c,
      label: data.function__c,
    };

    const staff = {
      name__c: data.staff_name__c,
      sfid: data.staff_id__c,
      profile__c: data.staff_profile__c,
    };

    const modData = {
      ...data,
      state,
      district,
      tehsil,
      territory,
      product,
      crop,
      targetFunction,
      staff,
    };

    setTargetData(modData);
    setViewOnly(true);
  };

  const debounceTimer = React.useRef(null);

  const debouncedSearchField = (event, name) => {
    const conditionalObject = {
      State: () => setStateLoading(true),
      District: () => setDistrictLoading(true),
      Tehsil: () => setTehsilLoading(true),
    };
    conditionalObject[name]();
    const { value } = event.target;

    if (value.length !== 0) {
      if (value.length <= 3) {
        return;
      }
    }

    clearTimeout(debounceTimer.current);
    setSearchField(value);

    debounceTimer.current = setTimeout(() => {
      const conditionalObject = {
        State: () => getStates(value),
        District: () => getDistricts(null, value),
        Tehsil: () => getTehsils(null, value),
      };

      conditionalObject[name]();
    }, 1500);
  };

  const renderContent = () => {
    return (
      <Box style={{ height: !data?.length ? '225px' : '450px' }}>
        <DataGridContainer>
          <DataGrid
            className='payrollGrid'
            rows={data || []}
            columns={getColumns()}
            density='compact'
            rowHeight={50}
            filterMode='server'
            paginationModel={paginationModel}
            paginationMode='server'
            onPaginationModelChange={setPaginationModel}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            rowCount={rowCount}
            disableRowSelectionOnClick
            checkboxSelection
            sx={{ borderRadius: '20px' }}
          />
        </DataGridContainer>
      </Box>
    );
  };

  const getColumns = () => {
    const stringOperators = getGridStringOperators().filter((op) =>
      ['contains'].includes(op.value)
    );

    let result = [
      {
        field: 'state_name__c',
        headerName: 'State',
        width: 250,
        filterable: false,
        sortable: false,
        valueGetter: (params) => params.row?.state_name__c,
        renderCell: (params) => {
          const value = params.row?.state_name__c || 'N/A';
          return <Tooltip title={value}>{value}</Tooltip>;
        },
        filterOperators: stringOperators,
      },
      {
        field: 'staff_name__c',
        headerName: 'Staff Name',
        width: 250,
        filterable: false,
        sortable: false,
        valueGetter: (params) => params.row?.staff_name__c,
        renderCell: (params) => {
          const value = params.row?.staff_name__c || 'N/A';
          return <Tooltip title={value}>{value}</Tooltip>;
        },
        filterOperators: stringOperators,
      },
      {
        field: 'staff_profile__c',
        headerName: 'Staff Profile',
        width: 200,
        filterable: false,
        sortable: false,
        valueGetter: (params) => params.row?.staff_profile__c,
        renderCell: (params) => {
          const value = params.row?.staff_profile__c || 'N/A';
          return <Tooltip title={value}>{value}</Tooltip>;
        },
        filterOperators: stringOperators,
      },
      {
        field: 'type_of_activity__c',
        headerName: 'Type Of Activity',
        width: 250,
        filterable: false,
        sortable: false,
        valueGetter: (params) => params.row?.type_of_activity__c,
        renderCell: (params) => {
          const value = params.row?.type_of_activity__c || 'N/A';
          return <Tooltip title={value}>{value}</Tooltip>;
        },
        filterOperators: stringOperators,
      },
      {
        field: 'product_name__c',
        headerName: 'Product',
        width: 200,
        filterable: false,
        sortable: false,
        valueGetter: (params) => params.row?.product_name__c,
        renderCell: (params) => {
          const value = params.row?.product_name__c || 'N/A';
          return <Tooltip title={value}>{value}</Tooltip>;
        },
        filterOperators: stringOperators,
      },
      {
        field: 'crop_name__c',
        headerName: 'Crop',
        width: 200,
        filterable: false,
        sortable: false,
        valueGetter: (params) => params.row?.crop_name__c,
        renderCell: (params) => {
          const value = params.row?.crop_name__c || 'N/A';
          return <Tooltip title={value}>{value}</Tooltip>;
        },
        filterOperators: stringOperators,
      },
      {
        field: 'year__c',
        headerName: 'Year',
        width: 120,
        filterable: false,
        sortable: false,
        valueGetter: (params) => params.row?.year__c,
        renderCell: (params) => {
          const value = params.row?.year__c || 'N/A';
          return <Tooltip title={value}>{value}</Tooltip>;
        },
        filterOperators: stringOperators,
      },
      {
        field: 'quater__c',
        headerName: 'Quarter',
        width: 150,
        filterable: false,
        sortable: false,
        valueGetter: (params) => params.row?.quater__c,
        renderCell: (params) => {
          const value = params.row?.quater__c?.split(';')?.join(', ') || 'N/A';
          return <Tooltip title={value}>{value}</Tooltip>;
        },
        filterOperators: stringOperators,
      },
      {
        field: 'approval_status__c',
        headerName: 'Status',
        width: 150,
        filterable: false,
        sortable: false,
        valueGetter: (params) => params.row?.approval_status__c,
        renderCell: (params) => {
          const value = params.row?.approval_status__c || 'N/A';
          let backgroundColor = 'transparent';

          if (value === 'Sent For Approval') backgroundColor = '#EAD307';
          else if (value === 'Rejected') backgroundColor = '#ED1C24';
          else if (value === 'Approved') backgroundColor = '#79B835';
          else if (value === 'N/A') backgroundColor = '#000';

          return (
            <Tooltip title={value}>
              <span
                style={{
                  backgroundColor,
                  fontWeight: '500',
                  padding: '4px 10px',
                  borderRadius: '50px',
                  color: '#FFF',
                }}
              >
                {value}
              </span>
            </Tooltip>
          );
        },
        filterOperators: stringOperators,
      },
      {
        field: 'action',
        headerName: 'Action',
        width: 150,
        filterable: false,
        sortable: false,
        valueGetter: (params) => params.row?.approval_status__c,
        renderCell: (params) => {
          return (
            <>
              {params.row?.approval_status__c === 'Rejected' ? (
                <Tooltip title={'Edit Target'}>
                  <IconButton onClick={() => handleEdit(params.row)}>
                    <DriveFileRenameOutlineIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title={'View Target'}>
                  <IconButton>
                    <VisibilityIcon onClick={() => handleView(params.row)} />
                  </IconButton>
                </Tooltip>
              )}
            </>
          );
        },
        filterOperators: stringOperators,
      },
    ];

    return result;
  };

  function downloadFile() {
    const arrOfTargetActivityData = [];
    const filteredObjects = targetActivityData.filter((obj) =>
      rowSelectionModel.includes(obj.heroku_id__c)
    );

    filteredObjects.map((item) => {
      const csvObj = {
        State: item.state_name__c,
        'Staff Name': item.staff_name__c,
        'Staff Profile': item.staff_profile__c,
        'Type Of Activity': item.type_of_activity__c,
        Product: item.product_name__c,
        Crop: item.crop_name__c,
        Year: item.year__c,
        Quarter: item?.quater__c?.split(';')?.join(','),
      };

      arrOfTargetActivityData.push(csvObj);
    });

    if (!arrOfTargetActivityData.length && !csvData.length) {
      window.NotificationUtils.showWarning(
        'There is no data to export in sheet.'
      );
    }

    const ws = XLSX.utils.json_to_sheet(
      arrOfTargetActivityData.length > 0 ? arrOfTargetActivityData : csvData
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    const wbout = XLSX.write(wb, { type: 'binary', bookType: 'xlsx' });
    const buf = new ArrayBuffer(wbout.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < wbout.length; i++) {
      view[i] = wbout.charCodeAt(i) & 0xff;
    }

    const a = document.createElement('a');
    const blob = new Blob([buf], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download =
      'Target_Activity_Data ' + new Date().toLocaleDateString() + '.xlsx';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  const handleDownloadSample = () => {
    const filePath =
      '../../../assets/bulk-upload-files/target-bulk-upload-sample.xlsx';

    saveAs(filePath, 'target-bulk-upload-sample.xlsx');
  };

  const handleUpload = () => {
    fileInputRef.current.click();
    handleClose();
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    try {
      const reader = new FileReader();

      reader.onload = async (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        const jsonData = XLSX.utils.sheet_to_json(sheet);

        console.log('Original Data:', jsonData);

        const mappingObject = {
          'Product ID': 'Product__c',
          'Product Name': 'Product_Name__c',
          'Crop ID': 'Crop__c',
          'Crop Name': 'Crop_Name__c',
          'State ID': 'State__c',
          'State Name': 'State_Name__c',
          'District ID': 'District__c',
          'District Name': 'District_Name__c',
          'Tehsil ID': 'Tehsil__c',
          'Tehsil Name': 'Tehsil_Name__c',
          'Staff ID': 'Staff_ID__c',
          'Staff Name': 'Staff_Name__c',
          'Staff Profile': 'Staff_Profile__c',
          Quarters: 'Quater__c',
          'Activity Type': 'Type_of_Activity__c',
          Function: 'Function__c',
          'Territory ID': 'Territory_Mapping__c',
          Year: 'Year__c',
          April: 'April_Target__c',
          May: 'May_Target__c',
          June: 'June_Target__c',
          July: 'July_Target__c',
          August: 'August_Target__c',
          September: 'September_Target__c',
          October: 'October_Target__c',
          November: 'November_Target__c',
          December: 'December_Target__c',
          January: 'January_Target__c',
          February: 'February_Target__c',
          March: 'March_Target__c',
        };

        const mappedData = jsonData.map((row) => {
          const mappedRow = {};

          for (const column in row) {
            if (mappingObject[column]) {
              mappedRow[mappingObject[column]] = row[column];
            }
          }

          return mappedRow;
        });

        console.log('Mapped Data:', mappedData);

        try {
          const response = await window.Platform.database.uploadTargetBulk(
            mappedData
          );

          if (response.hasError) {
            throw new Error('Failed to upload data');
          }

          console.log('Server response:', response);
        } catch (error) {
          console.error('Error uploading sheet:', error);
        }
      };

      reader.readAsArrayBuffer(file);
    } catch (error) {
      console.error('Error processing file:', error);
    }

    e.target.value = '';
  };

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        backgroundColor: '#F6F6F6',
        height: '100vh',
      }}
    >
      <Sidebar />
      <Container>
        <CustomHeader name='Event Targets' />
        <Box p={4}>
          <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
            justifyContent='space-between'
            mb={4}
          >
            <div
              style={{
                fontWeight: '600',
                fontSize: '36px',
                lineHeight: '54px',
              }}
            >
              EVENT TARGETS
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '10px',
              }}
            >
              <Button variant='contained' onClick={downloadFile}>
                <FileDownloadOutlinedIcon />
                &nbsp; Export
              </Button>
              <div>
                <Button
                  id='basic-button'
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  variant='contained'
                >
                  <FileUploadOutlinedIcon />
                  &nbsp; Import
                </Button>
                <Menu
                  id='basic-menu'
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem>
                    <a
                      target='_blank'
                      href='assets/target-bulk-upload-sample.xlsx'
                      download
                      onClick={handleClose}
                    >
                      Download Sample
                    </a>
                  </MenuItem>
                  <MenuItem onClick={handleUpload}>Import</MenuItem>
                </Menu>
              </div>
              <Button variant='contained' onClick={handleAdd}>
                Add
              </Button>
              <input
                type='file'
                accept='.csv, .xlsx, .xls'
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
            </div>
          </Box>
          <Box display='flex' alignItems='center' gap='10px' mb={2}>
            <Autocomplete
              value={selectedState || null}
              onChange={(event, newValue) => handleStateChange(newValue)}
              options={states}
              getOptionLabel={(option) => option.name__c}
              isOptionEqualToValue={(option, value) => option.name__c === value}
              fullWidth
              autoComplete='off'
              onFocus={() => {
                getStates();
              }}
              loading={stateLoading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='State'
                  onChange={(event) => debouncedSearchField(event, 'State')}
                  slotProps={{
                    input: {
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {stateLoading ? (
                            <CircularProgress color='inherit' size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    },
                  }}
                />
              )}
              filterOptions={(options, state) => {
                return options.filter((option) => option.name__c.toLowerCase());
              }}
            />

            {!selectedStateEnabled && selectedState ? (
              <Box
                sx={{
                  height: '94px',
                  width: '100%',
                }}
              >
                <Skeleton
                  variant='text'
                  width='100%'
                  height={90}
                  sx={{ marginBottom: '50px' }}
                  animation='wave'
                />
              </Box>
            ) : (
              <Tooltip
                title={
                  !selectedStateEnabled
                    ? 'Please select a state before choosing a district.'
                    : ''
                }
              >
                <Autocomplete
                  value={selectedDistrict || null}
                  onChange={(event, newValue) => handleDistrictChange(newValue)}
                  options={districts}
                  getOptionLabel={(option) => option.name__c}
                  isOptionEqualToValue={(option, value) =>
                    option.name__c === value
                  }
                  fullWidth
                  disabled={!selectedStateEnabled}
                  autoComplete='off'
                  onFocus={() => {
                    getDistricts();
                  }}
                  loading={districtLoading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='District'
                      onChange={(event) =>
                        debouncedSearchField(event, 'District')
                      }
                      slotProps={{
                        input: {
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {districtLoading ? (
                                <CircularProgress color='inherit' size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        },
                      }}
                    />
                  )}
                  filterOptions={(options, state) => {
                    return options.filter((option) =>
                      option.name__c.toLowerCase()
                    );
                  }}
                />
              </Tooltip>
            )}

            {!selectedDistrictEnabled && selectedDistrict ? (
              <Box
                sx={{
                  height: '94px',
                  width: '100%',
                }}
              >
                <Skeleton
                  variant='text'
                  width='100%'
                  height={90}
                  sx={{ marginBottom: '50px' }}
                  animation='wave'
                />
              </Box>
            ) : (
              <Tooltip
                title={
                  !selectedDistrictEnabled
                    ? 'Please select a district before choosing a tehsil.'
                    : ''
                }
              >
                <Autocomplete
                  value={selectedTehsil || null}
                  onChange={(event, newValue) => handleTehsilChange(newValue)}
                  options={tehsils}
                  getOptionLabel={(option) => option.name__c}
                  isOptionEqualToValue={(option, value) =>
                    option.name__c === value
                  }
                  fullWidth
                  disabled={!selectedDistrictEnabled}
                  autoComplete='off'
                  onFocus={() => {
                    getTehsils();
                  }}
                  loading={tehsilLoading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Tehsil'
                      onChange={(event) =>
                        debouncedSearchField(event, 'Tehsil')
                      }
                      slotProps={{
                        input: {
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {tehsilLoading ? (
                                <CircularProgress color='inherit' size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        },
                      }}
                    />
                  )}
                  filterOptions={(options, state) => {
                    return options.filter((option) =>
                      option.name__c.toLowerCase()
                    );
                  }}
                />
              </Tooltip>
            )}

            <Tooltip
              title={
                !selectedTerritory?.[0]?.name
                  ? 'Please select a tehsil to see the respective territory.'
                  : ''
              }
            >
              <TextField
                fullWidth
                label='Territory'
                InputLabelProps={{
                  shrink: selectedTerritory?.[0]?.name ? true : false,
                }}
                value={selectedTerritory?.[0]?.name || ''}
                disabled
              />
            </Tooltip>
          </Box>
          <StaffEditorPageContainer>
            <TableContainer>{renderContent()}</TableContainer>
          </StaffEditorPageContainer>
        </Box>

        {addModal && (
          <AddTarget
            onClose={() => {
              setAddModal(false);
              eventBus.emit('modalOpened', false);
            }}
            closeModal={() => setAddModal(false)}
            data={data}
            getTargetActivityData={getTargetActivityData}
            targetData={targetData}
            adding={adding}
            viewOnly={viewOnly}
          />
        )}
      </Container>
    </div>
  );
};

export default ViewTarget;
