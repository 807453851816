const icons = [
  {
    title: 'SFA Dashboard',
    link: '/sfa-dashboard',
    iconW: (
      <svg
        width='18'
        height='18'
        viewBox='0 0 18 18'
        fill='#FFF'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M10 5.95312V0H18V5.95312H10ZM0 9.92187V0H8V9.92187H0ZM10 17.8594V7.9375H18V17.8594H10ZM0 17.8594V11.9062H8V17.8594H0ZM2 7.9375H6V1.98437H2V7.9375ZM12 15.875H16V9.92187H12V15.875ZM12 3.96875H16V1.98437H12V3.96875ZM2 15.875H6V13.8906H2V15.875Z' />
      </svg>
    ),
    iconB: (
      <svg
        width='18'
        height='18'
        viewBox='0 0 18 18'
        fill='#464646'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M10 5.95312V0H18V5.95312H10ZM0 9.92187V0H8V9.92187H0ZM10 17.8594V7.9375H18V17.8594H10ZM0 17.8594V11.9062H8V17.8594H0ZM2 7.9375H6V1.98437H2V7.9375ZM12 15.875H16V9.92187H12V15.875ZM12 3.96875H16V1.98437H12V3.96875ZM2 15.875H6V13.8906H2V15.875Z' />
      </svg>
    ),
    iconG: (
      <svg
        width='18'
        height='18'
        viewBox='0 0 18 18'
        fill='#79B835'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M10 5.95312V0H18V5.95312H10ZM0 9.92187V0H8V9.92187H0ZM10 17.8594V7.9375H18V17.8594H10ZM0 17.8594V11.9062H8V17.8594H0ZM2 7.9375H6V1.98437H2V7.9375ZM12 15.875H16V9.92187H12V15.875ZM12 3.96875H16V1.98437H12V3.96875ZM2 15.875H6V13.8906H2V15.875Z' />
      </svg>
    ),
  },
  {
    title: 'View Target',
    link: '/view-target',
    iconW: (
      <svg
        width='20'
        height='21'
        viewBox='0 0 20 21'
        fill='#FFF'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M5 16.5H15V14.5H5V16.5ZM5 12.5H15V10.5H5V12.5ZM2.5 20.5C1.8125 20.5 1.22396 20.3042 0.734375 19.9125C0.244792 19.5208 0 19.05 0 18.5V2.5C0 1.95 0.244792 1.47917 0.734375 1.0875C1.22396 0.695833 1.8125 0.5 2.5 0.5H12.5L20 6.5V18.5C20 19.05 19.7552 19.5208 19.2656 19.9125C18.776 20.3042 18.1875 20.5 17.5 20.5H2.5ZM11.25 7.5V2.5H2.5V18.5H17.5V7.5H11.25Z' />
      </svg>
    ),
    iconB: (
      <svg
        width='20'
        height='21'
        viewBox='0 0 20 21'
        fill='#464646'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M5 16.5H15V14.5H5V16.5ZM5 12.5H15V10.5H5V12.5ZM2.5 20.5C1.8125 20.5 1.22396 20.3042 0.734375 19.9125C0.244792 19.5208 0 19.05 0 18.5V2.5C0 1.95 0.244792 1.47917 0.734375 1.0875C1.22396 0.695833 1.8125 0.5 2.5 0.5H12.5L20 6.5V18.5C20 19.05 19.7552 19.5208 19.2656 19.9125C18.776 20.3042 18.1875 20.5 17.5 20.5H2.5ZM11.25 7.5V2.5H2.5V18.5H17.5V7.5H11.25Z' />
      </svg>
    ),
    iconG: (
      <svg
        width='20'
        height='21'
        viewBox='0 0 20 21'
        fill='#79B835'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M5 16.5H15V14.5H5V16.5ZM5 12.5H15V10.5H5V12.5ZM2.5 20.5C1.8125 20.5 1.22396 20.3042 0.734375 19.9125C0.244792 19.5208 0 19.05 0 18.5V2.5C0 1.95 0.244792 1.47917 0.734375 1.0875C1.22396 0.695833 1.8125 0.5 2.5 0.5H12.5L20 6.5V18.5C20 19.05 19.7552 19.5208 19.2656 19.9125C18.776 20.3042 18.1875 20.5 17.5 20.5H2.5ZM11.25 7.5V2.5H2.5V18.5H17.5V7.5H11.25Z' />
      </svg>
    ),
  },
  {
    title: 'Add Target',
    link: '/view-target',
    iconW: (
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='#FFF'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M14.5 17.5H15.5V15H18V14H15.5V11.5H14.5V14H12V15H14.5V17.5ZM15 19.5C13.6167 19.5 12.4375 19.0125 11.4625 18.0375C10.4875 17.0625 10 15.8833 10 14.5C10 13.1167 10.4875 11.9375 11.4625 10.9625C12.4375 9.9875 13.6167 9.5 15 9.5C16.3833 9.5 17.5625 9.9875 18.5375 10.9625C19.5125 11.9375 20 13.1167 20 14.5C20 15.8833 19.5125 17.0625 18.5375 18.0375C17.5625 19.0125 16.3833 19.5 15 19.5ZM6 3.5V1.5H18V3.5H6ZM2 16.5C1.45 16.5 0.979167 16.3042 0.5875 15.9125C0.195833 15.5208 0 15.05 0 14.5C0 13.95 0.195833 13.4792 0.5875 13.0875C0.979167 12.6958 1.45 12.5 2 12.5C2.55 12.5 3.02083 12.6958 3.4125 13.0875C3.80417 13.4792 4 13.95 4 14.5C4 15.05 3.80417 15.5208 3.4125 15.9125C3.02083 16.3042 2.55 16.5 2 16.5ZM2 10.5C1.45 10.5 0.979167 10.3042 0.5875 9.9125C0.195833 9.52083 0 9.05 0 8.5C0 7.95 0.195833 7.47917 0.5875 7.0875C0.979167 6.69583 1.45 6.5 2 6.5C2.55 6.5 3.02083 6.69583 3.4125 7.0875C3.80417 7.47917 4 7.95 4 8.5C4 9.05 3.80417 9.52083 3.4125 9.9125C3.02083 10.3042 2.55 10.5 2 10.5ZM2 4.5C1.45 4.5 0.979167 4.30417 0.5875 3.9125C0.195833 3.52083 0 3.05 0 2.5C0 1.95 0.195833 1.47917 0.5875 1.0875C0.979167 0.695833 1.45 0.5 2 0.5C2.55 0.5 3.02083 0.695833 3.4125 1.0875C3.80417 1.47917 4 1.95 4 2.5C4 3.05 3.80417 3.52083 3.4125 3.9125C3.02083 4.30417 2.55 4.5 2 4.5ZM6 15.5V13.5H8.075C8.025 13.8333 8 14.1667 8 14.5C8 14.8333 8.025 15.1667 8.075 15.5H6ZM6 9.5V7.5H15C14.05 7.5 13.1583 7.67917 12.325 8.0375C11.4917 8.39583 10.7583 8.88333 10.125 9.5H6Z' />
      </svg>
    ),
    iconB: (
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='#464646'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M14.5 17.5H15.5V15H18V14H15.5V11.5H14.5V14H12V15H14.5V17.5ZM15 19.5C13.6167 19.5 12.4375 19.0125 11.4625 18.0375C10.4875 17.0625 10 15.8833 10 14.5C10 13.1167 10.4875 11.9375 11.4625 10.9625C12.4375 9.9875 13.6167 9.5 15 9.5C16.3833 9.5 17.5625 9.9875 18.5375 10.9625C19.5125 11.9375 20 13.1167 20 14.5C20 15.8833 19.5125 17.0625 18.5375 18.0375C17.5625 19.0125 16.3833 19.5 15 19.5ZM6 3.5V1.5H18V3.5H6ZM2 16.5C1.45 16.5 0.979167 16.3042 0.5875 15.9125C0.195833 15.5208 0 15.05 0 14.5C0 13.95 0.195833 13.4792 0.5875 13.0875C0.979167 12.6958 1.45 12.5 2 12.5C2.55 12.5 3.02083 12.6958 3.4125 13.0875C3.80417 13.4792 4 13.95 4 14.5C4 15.05 3.80417 15.5208 3.4125 15.9125C3.02083 16.3042 2.55 16.5 2 16.5ZM2 10.5C1.45 10.5 0.979167 10.3042 0.5875 9.9125C0.195833 9.52083 0 9.05 0 8.5C0 7.95 0.195833 7.47917 0.5875 7.0875C0.979167 6.69583 1.45 6.5 2 6.5C2.55 6.5 3.02083 6.69583 3.4125 7.0875C3.80417 7.47917 4 7.95 4 8.5C4 9.05 3.80417 9.52083 3.4125 9.9125C3.02083 10.3042 2.55 10.5 2 10.5ZM2 4.5C1.45 4.5 0.979167 4.30417 0.5875 3.9125C0.195833 3.52083 0 3.05 0 2.5C0 1.95 0.195833 1.47917 0.5875 1.0875C0.979167 0.695833 1.45 0.5 2 0.5C2.55 0.5 3.02083 0.695833 3.4125 1.0875C3.80417 1.47917 4 1.95 4 2.5C4 3.05 3.80417 3.52083 3.4125 3.9125C3.02083 4.30417 2.55 4.5 2 4.5ZM6 15.5V13.5H8.075C8.025 13.8333 8 14.1667 8 14.5C8 14.8333 8.025 15.1667 8.075 15.5H6ZM6 9.5V7.5H15C14.05 7.5 13.1583 7.67917 12.325 8.0375C11.4917 8.39583 10.7583 8.88333 10.125 9.5H6Z' />
      </svg>
    ),
    iconG: (
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='#79B835'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M14.5 17.5H15.5V15H18V14H15.5V11.5H14.5V14H12V15H14.5V17.5ZM15 19.5C13.6167 19.5 12.4375 19.0125 11.4625 18.0375C10.4875 17.0625 10 15.8833 10 14.5C10 13.1167 10.4875 11.9375 11.4625 10.9625C12.4375 9.9875 13.6167 9.5 15 9.5C16.3833 9.5 17.5625 9.9875 18.5375 10.9625C19.5125 11.9375 20 13.1167 20 14.5C20 15.8833 19.5125 17.0625 18.5375 18.0375C17.5625 19.0125 16.3833 19.5 15 19.5ZM6 3.5V1.5H18V3.5H6ZM2 16.5C1.45 16.5 0.979167 16.3042 0.5875 15.9125C0.195833 15.5208 0 15.05 0 14.5C0 13.95 0.195833 13.4792 0.5875 13.0875C0.979167 12.6958 1.45 12.5 2 12.5C2.55 12.5 3.02083 12.6958 3.4125 13.0875C3.80417 13.4792 4 13.95 4 14.5C4 15.05 3.80417 15.5208 3.4125 15.9125C3.02083 16.3042 2.55 16.5 2 16.5ZM2 10.5C1.45 10.5 0.979167 10.3042 0.5875 9.9125C0.195833 9.52083 0 9.05 0 8.5C0 7.95 0.195833 7.47917 0.5875 7.0875C0.979167 6.69583 1.45 6.5 2 6.5C2.55 6.5 3.02083 6.69583 3.4125 7.0875C3.80417 7.47917 4 7.95 4 8.5C4 9.05 3.80417 9.52083 3.4125 9.9125C3.02083 10.3042 2.55 10.5 2 10.5ZM2 4.5C1.45 4.5 0.979167 4.30417 0.5875 3.9125C0.195833 3.52083 0 3.05 0 2.5C0 1.95 0.195833 1.47917 0.5875 1.0875C0.979167 0.695833 1.45 0.5 2 0.5C2.55 0.5 3.02083 0.695833 3.4125 1.0875C3.80417 1.47917 4 1.95 4 2.5C4 3.05 3.80417 3.52083 3.4125 3.9125C3.02083 4.30417 2.55 4.5 2 4.5ZM6 15.5V13.5H8.075C8.025 13.8333 8 14.1667 8 14.5C8 14.8333 8.025 15.1667 8.075 15.5H6ZM6 9.5V7.5H15C14.05 7.5 13.1583 7.67917 12.325 8.0375C11.4917 8.39583 10.7583 8.88333 10.125 9.5H6Z' />
      </svg>
    ),
  },
];

export default icons;
