import React, { useContext, useEffect, useState } from 'react';
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  getGridStringOperators,
} from '@mui/x-data-grid';
import dayjs from 'dayjs';
import styled from 'styled-components';
import Drawer from '../../components/common/drawer/drawer';
import * as XLSX from 'xlsx';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
  Autocomplete,
  TextField,
} from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import CancelIcon from '@mui/icons-material/Cancel';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Typography,
  Button,
  Fab,
  Tabs,
  Tab,
  Divider,
  IconButton,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import OpaqueLoading from '../../components/opaqueLoading/opaqueLoading';

import OrderDetailsModal from '../../components/Modals/orderDeatilsModal';
import { AuthContext } from '../../components/contextAPI/ContextAPI';
import moment from 'moment';

const isMobile = window.innerWidth < 900;

const TabsContainer = styled.div`
  ${isMobile &&
  `
    height: calc(100vh - 56px - 20px - 100.77px);
  `}
`;
const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px lightGrey;
`;

const StaffEditorPageContainer = styled.div`
  height: ${isMobile ? `auto` : `calc(100vh - 82px)`};
  width: 98%;
  margin: auto;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
  `
      position: relative;
  
      `}
`;

const StyledDatePicker = styled(DatePicker)({
  '& .MuiInputBase-root': {
    height: '52px', // Adjust height of the input field
    borderRadius: '4px', // Rounded corners
  },
});
const DataGridContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  height: ${isMobile ? 'calc(100vh - 250px)' : 'calc(100vh - 285px)'};
  && .highlighted-row {
    background-color: #ffcccb !important;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? 'flex-start' : 'space-between'};
  margin-bottom: 4px;
  flex-direction: ${isMobile ? 'column' : 'row'};
`;

const TableContainer = styled.div`
  height: calc(
    100vh - ${isMobile ? '56px - 20px - 109.23px' : '172px - 7px - 54.90px'}
  );
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
  overflow-x: ${isMobile
    ? 'auto'
    : 'hidden'}; /* Add horizontal scroll for mobile */
`;

const FabStyle = styled(Fab)`
  && {
    z-index: 1;
  }
`;
const StyledCardHeading = styled(Typography)`
  text-transform: none;
  letter-spacing: 0.5px;
`;

export default function OrderManagement(props) {
  const [loading, setLoading] = useState(false);
  const { auth, setAuth } = useContext(AuthContext);
  console.log('DATAAUTH', props?.user?.plant_master__c);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [selectedRowIds1, setSelectedRowIds1] = useState([]);

  const [data, setData] = useState([]);

  const [rowCount, setRowCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationModel, setPaginationModel] = useState({
    page: currentPage - 1,
    pageSize: 12,
  });
  const [searchTerm, setSearchTerm] = useState('');
  const optionTerritoryType = [
    { id: 1, name: 'T1' },
    { id: 2, name: 'T2' },
  ];
  const [ytdFilter, setYtdFilter] = useState(false);
  const [mtdFilter, setmtdFilter] = useState(false);
  const [ftdFilter, setFtdFilter] = useState(false);
  const [cumalativeFilter, setCumalativeFilter] = useState(false);
  const [startDate, setStartDate] = useState();
  const [dataType, setDataType] = useState(false);
  const [endDate, setEndDate] = useState();
  const [territoryFilter, setTerritoryFilter] = useState(null);
  const [territoryType, setTerritoryType] = useState([]);
  const [territoryOptions, setTerritoryOptions] = useState([]);
  const [selectedTeritoryType, setSelectedTerritoryType] = useState(null);
  const [dateRange1, setDateRange1] = useState(false);
  // const [dateRange2,setDateRange2]=useState('')
  const [startDate1, setStartDate1] = useState();
  const [endDate1, setEndDate1] = useState();
  const [minDate, setMinDate] = useState();
  const [orderDetails, setOrderDetails] = useState(false);
  const [Details, setDetails] = useState(false);
  const [id, setid] = useState('');
  const [distributorId, setDistributorId] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);
  const [remark, setRemark] = useState();
  const [data1, setData1] = useState([]);
  const [selectedOption, setSelectedOption] = useState('agree');
  const [distributorData, setDistributorData] = useState([]);
  const [selectedDistributorName, setSelectedDistributorName] = useState('');
  const [selectedDistributorSfid, setSelectedDistributorSfid] = useState('');
  let t = false;

  useEffect(() => {
    // console.log('check page', paginationModel)
    if (selectedTab === 1) {
      fetchData1();
    } else {
      fetchData();
    }
  }, [paginationModel.page, selectedTab, selectedDistributorName]);

  const getAllData = async (e) => {
    console.log('value is*****', e);
    const value = e?.label || '';
    const accountId = e?.accountid || '';
    setLoading(true);
    setSelectedDistributorName(value);
    setSelectedDistributorSfid(accountId);
    if (!dataType) {
      setDataType(false);
    }

    let plantCode = localStorage.getItem('plantCode');

    try {
      let results = await window.Platform.database.getAllOrderDetails({
        pageNumber: paginationModel.page,
        plantCode: plantCode,
        NEW: false,
        startDate: startDate,
        endDate: endDate,
        dataType: dataType,
        distributorId: accountId,
      });

      const { distributorList = [] } = results;

      setDistributorData(distributorList);

      const jsonArrayWithId = results?.data?.map((obj, index) => ({
        ...obj,
        id: obj.heroku_id__c,
      }));

      setData1(jsonArrayWithId);
      setRowCount(results.count[0].count);
      setSelectedRowIds([]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoading(false);
  };

  const fetchData = async (details) => {
    try {
      setLoading(true);
      setData([]);
      setData1([]);

      setYtdFilter(false);
      setmtdFilter(false);
      setFtdFilter(false);
      setStartDate();
      setEndDate();
      setTerritoryFilter();
      if (startDate || selectedDistributorName) {
        setDateRange1(true);
      } else {
        setDateRange1(false);
      }

      let plantCode = localStorage.getItem('plantCode');
      console.log('PLANT', plantCode);
      let results = await window.Platform.database.getAllOrderDetails({
        plantCode: plantCode,
        NEW: true,
        startDate,
        endDate,
        distributorId: selectedDistributorSfid,
      });

      const { distributorList = [] } = results;
      setDistributorData(distributorList);
      console.log('RESPONSE', results);
      const jsonArrayWithId = results?.data?.map((obj, index) => ({
        ...obj,
        id: obj.heroku_id__c,
      }));

      setData(jsonArrayWithId);
      const territoryData = results?.region?.map((region, index) => {
        let temp = {
          id: index + 1,
          name: region.name,
          territoryMapping: region.territory_mapping1__c,
        };
        return temp;
      });
      console.log('TestData', territoryData);

      const resultMap = {};

      results?.region?.forEach((item) => {
        const territoryMapping = item.name;
        const subDistrictName = item.sub_district_name__c;

        if (!resultMap[territoryMapping]) {
          resultMap[territoryMapping] = [];
        }

        resultMap[territoryMapping].push(subDistrictName);
      });

      const resultArray = [];

      for (const territoryMapping in resultMap) {
        resultArray?.push({
          territory_mapping1__c: territoryMapping,
          sub_district_name__c: resultMap[territoryMapping],
        });
      }

      console.log(resultArray);

      setTerritoryType(resultArray);

      setLoading(false);
    } catch (e) {
      console.log('error', e.response);
      setLoading(false);
      if (e?.response?.status == 403) {
        window.NotificationUtils.showError(
          'Same account is loged in some other device.Want to ceate new login session then try to login again.'
        );
        setLoading(false);
      } else {
        window.NotificationUtils.showError(
          'Error While Recieving Data Please Wait and try again'
        );
        setLoading(false);
      }
    }
  };

  const fetchData1 = async () => {
    setLoading(true);
    setData([]);
    setData1([]);

    setYtdFilter(false);
    setmtdFilter(false);
    setFtdFilter(false);
    // setStartDate()
    // setEndDate()
    setTerritoryFilter();
    if (startDate || selectedDistributorName) {
      setDateRange1(true);
    } else {
      setDateRange1(false);
    }
    try {
      let plantCode = localStorage.getItem('plantCode');
      console.log('PLANT', plantCode);
      console.log('start date is ******', startDate);
      let results = await window.Platform.database.getAllOrderDetails({
        pageNumber: paginationModel.page,
        plantCode: plantCode,
        NEW: false,
        startDate,
        endDate,
        distributorId: selectedDistributorSfid,
      });
      const { distributorList = [] } = results;
      setDistributorData(distributorList);
      console.log('RESPONSE', results);
      const jsonArrayWithId = results?.data?.map((obj, index) => ({
        ...obj,
        id: obj.heroku_id__c,
      }));

      setData1(jsonArrayWithId);
      setRowCount(results.count[0].count);
      const territoryData = results?.region?.map((region, index) => {
        let temp = {
          id: index + 1,
          name: region.name,
          territoryMapping: region.territory_mapping1__c,
        };
        return temp;
      });
      console.log('TestData', territoryData);

      const resultMap = {};

      results?.region?.forEach((item) => {
        const territoryMapping = item.name;
        const subDistrictName = item.sub_district_name__c;

        if (!resultMap[territoryMapping]) {
          resultMap[territoryMapping] = [];
        }

        resultMap[territoryMapping].push(subDistrictName);
      });
      setLoading(false);
    } catch (err) {
      console.log(err);
      if (err?.response?.status == 403) {
        window.NotificationUtils.showError(
          'Same account is loged in some other device.Want to ceate new login session then try to login again.'
        );
        setLoading(false);
      } else {
        window.NotificationUtils.showError(
          'Error While Recieving Data Please Wait and try again'
        );
        setLoading(false);
      }
    }
  };

  const onFilterChange1 = async (filterModel) => {
    try {
      setLoading(true);
      console.log(
        filterModel,
        '................................................................'
      );
      if (filterModel) {
        const response = await window.Platform.database.getRetailerVisitFilter({
          filterField: filterModel.field,
          filterValue: filterModel.value,
          pageNumber: paginationModel.page,
          startDate: startDate,
          endDate: endDate,
          territoryName: territoryFilter,
        });
        console.log('respponse', response);
        const jsonArrayWithId = response?.data?.map((obj, index) => ({
          ...obj,
          id: index + 1,
        }));
        setData(jsonArrayWithId);
        setRowCount(response.count[0].count);
      } else {
        const response = await window.Platform.database.getRetailerVisitFilter({
          filterField: 'territory',
          filterValue: '',
          pageNumber: paginationModel.page,
          startDate: startDate,
          endDate: endDate,
          territoryName: territoryFilter,
        });
        console.log('respponse', response);
        const jsonArrayWithId = response?.data?.map((obj, index) => ({
          ...obj,
          id: index + 1,
        }));
        setData(jsonArrayWithId);
        setRowCount(response.count[0].count);
      }
      // setData(response.items);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
      window.NotificationUtils.showError(
        'Error While Recieving Data Please Wait and try again'
      );
    }
    // setQueryOptions({ filterModel: { ...filterModel } });
  };

  const getColumns1 = () => {
    const stringOperators = getGridStringOperators().filter((op) =>
      ['contains'].includes(op.value)
    );
    let result = [
      {
        field: 'heroku_id__c',
        headerName: ' Order ID',
        sortable: false,
        width: 250,
        filterable: false,
        valueGetter: (params) => params.row?.orderid,
        renderCell: (params) => {
          let val = params.row?.orderid;
          return (
            <Tooltip style={{ color: '#0000ff' }} title={val}>
              {val}
            </Tooltip>
          );
        },
        filterOperators: stringOperators,
      },
      {
        field: 'name',
        headerName: ' Name',
        sortable: false,
        width: 250,
        filterable: false,
        valueGetter: (params) => params.row?.name,
        renderCell: (params) => {
          let val = params.row?.name || 'N/A';
          return <Tooltip title={val}>{val}</Tooltip>;
        },
        filterOperators: stringOperators,
        // filterOperators: ['Contains'],
      },
      {
        field: 'distributor_sap_id',
        headerName: 'Sap ID',
        sortable: false,
        width: 250,
        filterable: false,
        valueGetter: (params) => params.row?.distributor_sap_id,
        renderCell: (params) => {
          let val = params.row?.distributor_sap_id || 'N/A';
          return <Tooltip title={val}>{val}</Tooltip>;
        },
        filterOperators: stringOperators,
        // filterOperators: ['Contains'],
      },
      {
        field: 'city__c',
        headerName: 'City',
        sortable: false,
        width: 250,
        filterable: false,
        valueGetter: (params) => params.row?.city__c,
        renderCell: (params) => {
          let val = params.row?.city__c || 'N/A';
          return <Tooltip title={val}>{val}</Tooltip>;
        },
        filterOperators: stringOperators,
        // filterOperators: ['Contains'],
      },
      {
        field: 'mobile__c',
        headerName: 'Mobile No',
        width: 250,
        filterable: false,

        sortable: false,
        valueGetter: (params) => params.row?.mobile__c || 'N/A',
        renderCell: (params) => {
          // console.log(params.row?.container?.id)
          const value = params.row?.mobile__c || 'N/A';
          return <Tooltip title={value}>{value}</Tooltip>;
        },
        filterOperators: stringOperators,
      },
      {
        field: 'effectivedate',
        headerName: 'Order Date',
        width: 250,
        filterable: false,

        sortable: false,
        valueGetter: (params) =>
          (params.row?.effectivedate &&
            moment(params.row.effectivedate).format('DD-MM-YYYY')) ||
          'N/A',
        renderCell: (params) => {
          // console.log(params.row?.container?.id)
          const value =
            (params.row?.effectivedate &&
              moment(params.row.effectivedate).format('DD-MM-YYYY')) ||
            'N/A';
          return <Tooltip title={value}>{value}</Tooltip>;
        },
        filterOperators: stringOperators,
      },
      {
        field: 'totalamount',
        headerName: 'Total Amount',
        width: 200,
        sortable: false,
        filterable: false,
        valueGetter: (params) => {
          const value = params.row?.totalamount.toFixed(2);
          return value;
        },
        renderCell: (params) => {
          const value = params.row?.totalamount.toFixed(2);
          // console.log(value)
          if (typeof value === 'number' || !isNaN(parseFloat(value))) {
            const formattedValue = parseFloat(value).toLocaleString('en-IN');
            return <Tooltip title={formattedValue}>{formattedValue}</Tooltip>;
          } else {
            return <Tooltip title='N/A'>N/A</Tooltip>;
          }
          //   console.log(isNaN(value),"Checek")
          //   const formattedValue = value.toLocaleString('en-IN')||"N/A"
          //   return <Tooltip title={formattedValue}>{formattedValue}</Tooltip>
          //
        },
        filterOperators: stringOperators,
        filterable: false,
      },
      {
        field: 'remarks',
        headerName: 'Remarks',
        width: 150,
        sortable: false,
        filterable: false,
        valueGetter: (params) => {
          const value = params.row?.remarks__c || 'N/A';
          return value;
        },
        renderCell: (params) => {
          // console.log(params.row.quotation.attributes.shippingOrders.parent.id)
          const value = params.row.remarks__c || 'N/A';
          return <Tooltip title={value}>{value}</Tooltip>;
        },
        filterOperators: stringOperators,
        filterable: false,
      },
    ];

    return result;
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        {/* <GridToolbarFilterButton /> */}
        <GridToolbarDensitySelector />
        {/* <GridToolbarExport
          csvOptions={{
            fileName:"FarmerManagement",
          }}
        /> */}
      </GridToolbarContainer>
    );
  }

  const getDetails = async (data) => {
    try {
      setLoading(true);
      let results = await window.Platform.database.getOrderDetailsById({
        herokuId: data,
      });
      console.log('RESULTS', results.data.orderDetails);
      setDetails(results.data);

      setLoading(false);
      if (results) {
        setOrderDetails(true);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  function formatDatetoFormat(inputDateStr) {
    console.log('checking date', inputDateStr);
    // Parse the input date string
    var inputDate = new Date(inputDateStr);

    // Desired date and time components
    var month = inputDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month
    var day = inputDate.getDate();
    var year = inputDate.getFullYear();
    var hour = inputDate.getHours();
    var minute = inputDate.getMinutes();
    var second = inputDate.getSeconds();

    // Add leading zeros to minute and second if needed
    minute = padZero(minute);
    second = padZero(second);

    // Construct the formatted date string
    var formattedDate =
      year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;

    return formattedDate;
  }

  // Helper function to add leading zeros
  function padZero(num) {
    return (num < 10 ? '0' : '') + num;
  }

  // Function to keep the date format as 'YYYY-M-DD HH:MM:SS'
  function formatDateData(date) {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = d.getMonth() + 1; // No leading zero for month
    const day = d.getDate(); // No leading zero for day
    const hours = d.getHours().toString().padStart(2, '0');
    const minutes = d.getMinutes().toString().padStart(2, '0');
    const seconds = d.getSeconds().toString().padStart(2, '0');

    return `"${year}-${month}-${day} ${hours}:${minutes}:${seconds}"`;
  }

  // Function to convert JSON to CSV string
  function convertJSONToCSV(jsonData, columnHeaders) {
    // Check if JSON data is empty
    if (jsonData.length === 0) {
      return '';
    }

    // Create headers string
    const headers = columnHeaders.join(',') + '\n';

    // Map JSON data to CSV rows
    const rows = jsonData
      .map((row) => {
        // Map each row to CSV format
        return columnHeaders.map((field) => row[field] || '').join(',');
      })
      .join('\n');

    // Combine headers and rows
    return headers + rows;
  }

  const convertDate = (data) => {
    const newDate = data + ' ' + 'GMT-0530';
    const convertedDate = new Date(newDate);

    // Get individual components
    const year = convertedDate.getUTCFullYear();
    const month = String(convertedDate.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(convertedDate.getUTCDate()).padStart(2, '0');
    const hours = String(convertedDate.getUTCHours()).padStart(2, '0');
    const minutes = String(convertedDate.getUTCMinutes()).padStart(2, '0');
    const seconds = String(convertedDate.getUTCSeconds()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
  };

  const exportData = async () => {
    try {
      if (selectedRowIds.length === 0) {
        window.NotificationUtils.showWarning(
          'Please select checkbox to export data'
        );

        return;
      }
      setLoading(true);

      let results = await window.Platform.database.getAllOrderDetailsExport({
        herokuId: selectedRowIds,
        newData: true,
      });

      // let data = results?.data?.map((item) => {
      //   let arr=[]
      //   console.log(item)
      //   let csvData=item?.order?.map((order) => {
      //             let newData= {
      //               orderNumber: item?.distributor?.ordernumber,
      //               date: item?.distributor?.effectivedate,
      //               distributorName: item?.distributor?.name,
      //               ReportedByName:order?.name__c,
      //               ReportedByRole:order?.profile__c,
      //               orderItemNumber:order?.orderitemnumber,
      //               productName:order?.product_name__c,
      //               quantity:order?.quantity,
      //               unitPrice:order?.unitprice,
      //               totalPrice:order?.totalprice,

      //               remarks: item?.distributor?.remarks__c || ''
      //             }
      //             return arr.push(newData)
      //            })
      //            console.log(arr)
      //            return arr
      //           })
      let data = results?.data
        ?.map((item) => {
          return item?.order?.map((order) => ({
            orderId: order?.orderid,
            orderId1: order?.orderid,
            status: '0',
            latitude: '0',
            longitude: '0',
            dateTime: item?.distributor?.effectivedate
              ? convertDate(item?.distributor?.effectivedate)
              : 'N/A',
            order_value:
              order?.quantity *
              order?.unitprice *
              order?.product_conversion_rate__c,
            distributor_br_id: item?.distributor?.distributor_sap_id,
            distributor_erp_id: item?.distributor?.distributor_sap_id,
            distributor_erp_id: item?.distributor?.distributor_sap_id,
            distributorName:
              item?.distributor?.name +
              ((item?.distributor?.city__c &&
                ' - ' + item?.distributor?.city__c) ||
                ''),
            'Reported-By ID': order?.employee_sap_id || '',
            'Reported-By Name': order?.name__c ? order?.name__c : '',
            'Reported-By Role': order?.profile__c || '',
            'Reported-By Email': order?.email__c || '',
            sku_br_id: order?.material_number__c,
            sku_erp_id: order?.material_number__c,
            description: order?.product_name__c,
            brand: ' ',
            sub_brand: ' ',
            uom: ' ',
            skuPrice: order?.unitprice,
            quantity: order?.quantity,
            Plant: order?.plant__c || ' ',
            value:
              order?.quantity *
              order?.unitprice *
              order?.product_conversion_rate__c,
            remarks: item?.distributor?.remarks__c || '',
            order_timestamp:
              new Date(item?.distributor?.effectivedate).getTime() / 1000,

            Ack_Order_ID: '0',
          }));
        })
        .flat();

      console.log('data****', data);

      // const ws = XLSX.utils.json_to_sheet(data);
      // const wb = XLSX.utils.book_new();
      // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      // const filename = 'exported-order-data.xlsx';
      // XLSX.writeFile(wb, filename);
      //  setLoading(false)

      const headers = [
        'orderId',
        'orderId1',
        'status',
        'latitude',
        'longitude',
        'dateTime',
        'order_value',
        'distributor_br_id',
        'distributor_erp_id',
        'distributor_erp_id',
        'distributorName',
        'Reported-By ID',
        'Reported-By Name',
        'Reported-By Role',
        'Reported-By Email',
        'sku_br_id',
        'sku_erp_id',
        'description',
        'brand',
        'sub_brand',
        'uom',
        'skuPrice',
        'quantity',
        'Plant',
        'value',
        'remarks',
        'order_timestamp',
        'Ack_Order_ID',
      ];

      const ws = XLSX.utils.json_to_sheet(data);
      const csv = XLSX.utils.sheet_to_csv(ws); // Convert the worksheet to CSV format
      const filename = 'exported-order-data.csv';

      // Create a Blob from the CSV data
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

      // Create a link element to download the CSV
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      //csv file download functionality
      // const csvData = convertJSONToCSV(data, headers);

      // // Create CSV file and initiate download
      // const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      // const link = document.createElement('a');
      // link.href = URL.createObjectURL(blob);
      // link.setAttribute('download', 'exported-order-data.csv');
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err?.response?.status == 403) {
        window.NotificationUtils.showError(
          'Same account is loged in some other device.Want to ceate new login session then try to login again.'
        );
        setLoading(false);
      } else {
        window.NotificationUtils.showError(
          'Error While Recieving Data Please Wait and try again'
        );
        setLoading(false);
      }
    }
  };

  console.log('data ghjgj****', localStorage.getItem('dataType'));

  const renderContent = () => {
    if (selectedTab === 0) {
      return (
        <DataGridContainer>
          <DataGrid
            className='payrollGrid'
            checkboxSelection={true}
            rows={data || []}
            columns={getColumns1()}
            density='compact'
            rowHeight={50}
            onRowClick={(params) => {
              console.log('RowClick', params.row);
              if (params?.row?.remarks__c === null) {
                setRemark('');
              } else {
                setRemark(params.row.remarks__c);
              }
              localStorage.setItem('hId', params.row.heroku_id__c);
              setid(params.row.orderid);
              setDistributorId(params.row.accountid);
              getDetails(params.row.heroku_id__c);
            }}
            onRowSelectionModelChange={(selectedRowIndices) => {
              console.log('Selected Row Indices:', selectedRowIndices);

              // Get the selected rows' data using the selected indices

              // console.log("Selected Row Data:", selectedRowIndices);

              setSelectedRowIds(selectedRowIndices);
            }}
            disableRowSelectionOnClick
            autoPageSize
          />
        </DataGridContainer>
      );
    } else if (selectedTab === 1) {
      return (
        <DataGridContainer>
          <DataGrid
            className='payrollGrid'
            disableSelectionOnClick={true}
            checkboxSelection={true}
            hideFooterPagination={dataType}
            disableRowSelectionOnClick
            rows={data1 || []}
            columns={getColumns1()}
            rowHeight={50}
            density='compact'
            onRowClick={(params) => {
              console.log('RowClick', params.row);
              if (params?.row?.remarks__c === null) {
                setRemark('');
              } else {
                setRemark(params.row.remarks__c);
              }
              localStorage.setItem('hId', params.row.heroku_id__c);
              setid(params.row.orderid);
              setDistributorId(params.row.accountid);
              getDetails(params.row.heroku_id__c);
            }}
            rowSelectionModel={selectedRowIds}
            onRowSelectionModelChange={(selectedRowIndices) => {
              console.log('Selected Row Indices:', selectedRowIndices);

              // Get the selected rows' data using the selected indices

              // console.log("Selected Row Data:", selectedRowIndices);

              setSelectedRowIds(selectedRowIndices);
            }}
            filterMode='server'
            onFilterModelChange={(val) => {
              console.log('vvvv', val);
              //     setQueryOptions(val);
              //     if(activeTab === 0)

              if (val?.items[0]?.value?.length > 0) {
                onFilterChange1(val.items[0]);
                setSearchTerm(val.items[0]);
                // else
                // onFilterChange2(val)
              } else if (
                !val.items?.value &&
                !endDate &&
                !startDate &&
                !territoryFilter
              ) {
                setSearchTerm(null);
                fetchData();
                console.log('CHECK1');
              } else if (
                !val.items?.value &&
                endDate &&
                startDate &&
                territoryFilter
              ) {
                setSearchTerm(null);

                onFilterChange1();
                console.log('checkterr');
              } else if (
                !val.items?.value &&
                endDate &&
                startDate &&
                !territoryFilter
              ) {
                setSearchTerm(null);
                console.log('check2');
                onFilterChange1();
              } else {
                setSearchTerm(null);
                console.log('check2');
                onFilterChange1();
              }
            }}
            rowCount={rowCount}
            // pageSizeOptions={[10]}
            paginationModel={paginationModel}
            paginationMode='server'
            onPaginationModelChange={setPaginationModel}
            // =autoPageSize

            // onRowClick={handleRowClick}
            //   getRowClassName={getRowClassName}
          />
        </DataGridContainer>
      );
    }
  };
  function formatDateToYYYYMMDD(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  console.log('CurrentPage', paginationModel.page);

  const YTD = async () => {
    try {
      setYtdFilter(true);
      setmtdFilter(false);
      setFtdFilter(false);
      setCumalativeFilter(false);
      setLoading(true);

      const today = new Date();
      const startOfYear = new Date(today.getFullYear(), 0, 1);

      const formattedStartDate = formatDateToYYYYMMDD(startOfYear);
      const formattedCurrentDate = formatDateToYYYYMMDD(today);

      console.log('Start of Current Year:', formattedStartDate);
      setStartDate(formattedStartDate);
      console.log('Current Date:', formattedCurrentDate);
      setEndDate(formattedCurrentDate);
      if (searchTerm) {
        console.log('Current Date:check1');

        const response = await window.Platform.database.getRetailerVisitFilter({
          filterField: searchTerm?.field,
          filterValue: searchTerm?.value,
          pageNumber: paginationModel.page,
          startDate: formattedStartDate,
          endDate: formattedCurrentDate,
          territoryName: territoryFilter,
        });
        const jsonArrayWithId = response?.data?.map((obj, index) => ({
          ...obj,
          id: index + 1,
        }));
        setData(jsonArrayWithId);
        // setData(response.items);
        setRowCount(response.count[0].count);
      } else {
        console.log('Current Date:check2');

        const response = await window.Platform.database.getRetailerVisitFilter({
          filterField: 'ytd',
          filterValue: searchTerm,
          pageNumber: paginationModel.page,
          startDate: formattedStartDate,
          endDate: formattedCurrentDate,
          territoryName: territoryFilter,
        });
        const jsonArrayWithId = response?.data?.map((obj, index) => ({
          ...obj,
          id: index + 1,
        }));
        setData(jsonArrayWithId);
        // setData(response.items);
        setRowCount(response.count[0].count);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setYtdFilter(false);
      window.NotificationUtils.showError(
        'Error While Recieving Data Please Wait and try again'
      );
      setLoading(false);
      fetchData();
    }
  };
  const MTD = async () => {
    try {
      setmtdFilter(true);
      setYtdFilter(false);
      setFtdFilter(false);
      setCumalativeFilter(false);
      setLoading(true);
      const today = new Date();
      const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

      const formattedStartOfMonth = formatDateToYYYYMMDD(startOfMonth);
      const formattedCurrentDate = formatDateToYYYYMMDD(today);

      console.log('Start of Current Month:', formattedStartOfMonth);
      setStartDate(formattedStartOfMonth);
      console.log('Current Date:', formattedCurrentDate);
      setEndDate(formattedCurrentDate);
      if (searchTerm) {
        const response = await window.Platform.database.getRetailerVisitFilter({
          filterField: searchTerm.field,
          filterValue: searchTerm.value,
          pageNumber: paginationModel.page,
          startDate: formattedStartOfMonth,
          endDate: formattedCurrentDate,
          territoryName: territoryFilter,
        });
        const jsonArrayWithId = response?.data?.map((obj, index) => ({
          ...obj,
          id: index + 1,
        }));
        setData(jsonArrayWithId);
        // setData(response.items);
        setRowCount(response.count[0].count);
      } else {
        const response = await window.Platform.database.getRetailerVisitFilter({
          filterField: 'mtd',
          filterValue: searchTerm,
          pageNumber: paginationModel.page,
          startDate: formattedStartOfMonth,
          endDate: formattedCurrentDate,
          territoryName: territoryFilter,
        });
        const jsonArrayWithId = response?.data?.map((obj, index) => ({
          ...obj,
          id: index + 1,
        }));
        setData(jsonArrayWithId);
        // setData(response.items);
        setRowCount(response.count[0].count);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setmtdFilter(false);
      setLoading(false);
      window.NotificationUtils.showError(
        'Error While Recieving Data Please Wait and try again'
      );
      fetchData();
    }
  };
  const FTD = async () => {
    try {
      setFtdFilter(true);
      setmtdFilter(false);
      setYtdFilter(false);
      setCumalativeFilter(false);
      setLoading(true);
      const today = new Date();

      const formattedCurrentDate = formatDateToYYYYMMDD(today);
      setStartDate(formattedCurrentDate);

      console.log('Current Date:', formattedCurrentDate);
      if (searchTerm) {
        const response = await window.Platform.database.getRetailerVisitFilter({
          filterField: searchTerm.field,
          filterValue: searchTerm.value,
          pageNumber: paginationModel.page,
          startDate: formattedCurrentDate,
          territoryName: territoryFilter,
        });
        const jsonArrayWithId = response?.data?.map((obj, index) => ({
          ...obj,
          id: index + 1,
        }));
        setData(jsonArrayWithId);
        // setData(response.items);
        setRowCount(response.count[0].count);
      } else {
        const response = await window.Platform.database.getRetailerVisitFilter({
          filterField: 'ftd',
          filterValue: searchTerm,
          pageNumber: paginationModel.page,
          startDate: formattedCurrentDate,
          territoryName: territoryFilter,
        });
        const jsonArrayWithId = response?.data?.map((obj, index) => ({
          ...obj,
          id: index + 1,
        }));
        setData(jsonArrayWithId);
        // setData(response.items);
        setRowCount(response.count[0].count);
      }
      console.log('Response');
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      window.NotificationUtils.showError(
        'Error While Recieving Data Please Wait and try again'
      );

      setFtdFilter(false);
      fetchData();
    }
  };

  const fetchTerritoryFilter = async (data) => {
    try {
      setLoading(true);

      console.log('SEARCH', searchTerm);
      if (searchTerm) {
        console.log('CHECKFILTER1');
        const response = await window.Platform.database.getRetailerVisitFilter({
          filterField: searchTerm.field,
          filterValue: searchTerm?.value,
          pageNumber: paginationModel.page,
          startDate: startDate,
          endDate: endDate,
          territoryName: data,
        });
        const jsonArrayWithId = response?.data?.map((obj, index) => ({
          ...obj,
          id: index + 1,
        }));
        setData(jsonArrayWithId);
        // setData(response.items);
        setRowCount(response.count[0].count);
      } else {
        console.log('CHECKFILTER2');

        const response = await window.Platform.database.getRetailerVisitFilter({
          filterField: 'territory',
          filterValue: searchTerm?.value,
          pageNumber: paginationModel.page,
          startDate: startDate,
          endDate: endDate,
          territoryName: data,
        });
        const jsonArrayWithId = response?.data?.map((obj, index) => ({
          ...obj,
          id: index + 1,
        }));
        setData(jsonArrayWithId);
        // setData(response.items);
        setRowCount(response.count[0].count);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      window.NotificationUtils.showError(
        'Error While Recieving Data Please Wait and try again'
      );
      fetchData();
    }
  };
  const formatDate = async (data) => {
    console.log('data is check ***6767', data);
    setMinDate(data);
    setDateRange1(true);
    setEndDate1(null);
    setEndDate(null);
    const datePickerResponse = new Date(data.$d);

    const year = datePickerResponse.getFullYear();
    const month = String(datePickerResponse.getMonth() + 1).padStart(2, '0');
    const day = String(datePickerResponse.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    setStartDate1(formattedDate);
    setStartDate(formattedDate);
    console.log('CHECK!!');
    console.log('CHECKENDDATE', endDate);
  };
  const finalDateRangeFilter = async (data) => {
    try {
      setLoading(true);

      const datePickerResponse = new Date(data);

      const year = datePickerResponse.getFullYear();
      const month = String(datePickerResponse.getMonth() + 1).padStart(2, '0');
      const day = String(datePickerResponse.getDate()).padStart(2, '0');

      const formattedDate = `${year}-${month}-${day}`;

      if (data) {
        setEndDate(formattedDate);
        setEndDate1(formattedDate);
      }
      let plantCode = localStorage.getItem('plantCode');
      if (selectedTab == 0) {
        let results = await window.Platform.database.getAllOrderDetails({
          plantCode: plantCode,
          NEW: true,
          startDate: startDate,
          endDate: formattedDate,
          distributorId: selectedDistributorSfid,
        });
        const { distributorList = [] } = results;
        setDistributorData(distributorList);
        console.log('RESPONSE', results);
        const jsonArrayWithId = results?.data?.map((obj, index) => ({
          ...obj,
          id: obj.heroku_id__c,
        }));

        setData(jsonArrayWithId);
      } else {
        console.log('PLANT', plantCode);
        let results = await window.Platform.database.getAllOrderDetails({
          pageNumber: paginationModel.page,
          plantCode: plantCode,
          NEW: false,
          startDate: startDate,
          endDate: formattedDate,
          distributorId: selectedDistributorSfid,
        });
        const { distributorList = [] } = results;
        setDistributorData(distributorList);
        console.log('RESPONSE', results);
        const jsonArrayWithId = results?.data?.map((obj, index) => ({
          ...obj,
          id: obj.heroku_id__c,
        }));

        setData1(jsonArrayWithId);
        setRowCount(results.count[0].count);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      window.NotificationUtils.showError(
        'Error While Recieving Data Please Wait and try again'
      );

      fetchData();
    }
  };

  console.log('data is *****Record***', localStorage.getItem('dataType'));
  const clearDateFilter = async () => {
    setStartDate(null);
    setEndDate(null);
    setStartDate1(null);
    setEndDate1(null);
    setDateRange1(false);
    setSelectedDistributorName('');
    setSelectedDistributorSfid('');
    setSelectedRowIds([]);
    setDataType(false);
    try {
      setLoading(true);
      let plantCode = localStorage.getItem('plantCode');
      if (selectedTab == 0) {
        let results = await window.Platform.database.getAllOrderDetails({
          plantCode: plantCode,
          NEW: true,
        });
        const { distributorList = [] } = results;
        setDistributorData(distributorList);
        console.log('RESPONSE', results);
        const jsonArrayWithId = results?.data?.map((obj, index) => ({
          ...obj,
          id: obj.heroku_id__c,
        }));

        setData(jsonArrayWithId);
      } else {
        console.log('PLANT', plantCode);
        let results = await window.Platform.database.getAllOrderDetails({
          pageNumber: paginationModel.page,
          plantCode: plantCode,
          NEW: false,
        });
        const { distributorList = [] } = results;
        setDistributorData(distributorList);
        console.log('RESPONSE', results);
        const jsonArrayWithId = results?.data?.map((obj, index) => ({
          ...obj,
          id: obj.heroku_id__c,
        }));

        setData1(jsonArrayWithId);
        setRowCount(results.count[0].count);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      window.NotificationUtils.showError(
        'Error While Recieving Data Please Wait and try again'
      );
    }
  };

  const clearTerritoryFIlter = async () => {
    setTerritoryFilter(null);
    setSelectedTerritoryType(null);
    setTerritoryOptions(null);
    console.log(territoryFilter, selectedTeritoryType);
    if (searchTerm || startDate || endDate) {
      try {
        setLoading(true);
        const response = await window.Platform.database.getRetailerVisitFilter({
          filterField: searchTerm ? searchTerm.field : 'territory',
          filterValue: searchTerm?.value,
          pageNumber: paginationModel.page,
          startDate: startDate,
          endDate: endDate,
          territoryName: '',
        });
        const jsonArrayWithId = response?.data?.map((obj, index) => ({
          ...obj,
          id: index + 1,
        }));
        setData(jsonArrayWithId);
        // setData(response.items);
        setRowCount(response.count[0].count);
        setLoading(false);
      } catch (e) {
        console.log(e);
        window.NotificationUtils.showError(
          'Error While Recieving Data Please Wait and try again'
        );
        fetchData();
      }
    } else {
      fetchData();
    }
  };

  const CumulativeFiltefunctionr = async () => {
    try {
      setLoading(true);
      setStartDate(null);
      setEndDate(null);
      setYtdFilter(false);
      setmtdFilter(false);
      setFtdFilter(false);
      setCumalativeFilter(true);
      // setLoading(true)
      if (searchTerm || territoryFilter) {
        const response = await window.Platform.database.getRetailerVisitFilter({
          filterField: searchTerm ? searchTerm?.field : 'territory',
          filterValue: searchTerm?.value,
          pageNumber: paginationModel.page,
          startDate: '',
          endDate: '',
          territoryName: territoryFilter,
        });
        const jsonArrayWithId = response?.data?.map((obj, index) => ({
          ...obj,
          id: index + 1,
        }));
        setData(jsonArrayWithId);
        // setData(response.items);
        setRowCount(response.count[0].count);
        setLoading(false);
      } else {
        fetchData();
      }
    } catch (e) {
      console.log(e);
      fetchData();
      setLoading(false);
    }
  };

  const handleTabChange = async (value) => {
    console.log(value);
    setSelectedTab(value);
    // if(value === TABS.SEA){
    // }
    // else if(value === TABS.AIR)
    // {
    //   setShippingOrders(AIR_SHIPPING_ORDERS)
    // }
  };

  const handleRadioChange = async (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    setLoading(true);
    setDataType(true);
    setSelectedRowIds([]);
    let plantCode = localStorage.getItem('plantCode');
    let results = await window.Platform.database.getAllOrderDetails({
      pageNumber: paginationModel.page,
      plantCode: plantCode,
      NEW: false,
      startDate: startDate,
      endDate: endDate,
      dataType: true,
      distributorId: selectedDistributorSfid,
    });
    const { distributorList = [] } = results;
    setDistributorData(distributorList);
    const jsonArrayWithId = results?.data?.map((obj, index) => ({
      ...obj,
      id: obj.heroku_id__c,
    }));

    setData1(jsonArrayWithId);
    setRowCount(results.count[0].count);
    setLoading(false);
  };

  return (
    <>
      {isMobile && <Drawer props={props} />}
      <StaffEditorPageContainer>
        <HeaderContainer>
          <Typography variant='h5'>Order Management</Typography>
        </HeaderContainer>

        <HeaderContainer>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              gap: '20px',
              paddingTop: '4px',
              marginBottom: '10px',
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StyledDatePicker
                label='Start Date'
                value={startDate1}
                disableFuture
                disabled={ftdFilter || mtdFilter || ytdFilter || loading}
                format='DD/MM/YYYY'
                onChange={(data) => formatDate(data)}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StyledDatePicker
                label='End Date'
                minDate={minDate}
                disableFuture
                value={endDate1}
                disabled={
                  ftdFilter || mtdFilter || ytdFilter || !dateRange1 || loading
                }
                format='DD/MM/YYYY'
                onChange={(data) => finalDateRangeFilter(data.$d)}
              />
            </LocalizationProvider>

            <Autocomplete
              disablePortal
              options={distributorData}
              value={selectedDistributorName}
              sx={{ width: 300, height: '48px !important' }}
              renderInput={(params) => (
                <TextField {...params} label='Select Distributor' />
              )}
              onChange={(event, value) => getAllData(value)}
            />
            {/* <select
              style={{ width: "20%", display: "flex", flexDirection: "row", gap: "20px", paddingTop: "4px", marginBottom: "10px" }}
              value={selectedDistributorName}
              onChange={(e) => getAllData(e)}
            >
              <option value="" disabled selected>
                Select Distributor
              </option>
              {distributorData.map((state) => (
                <option key={state.accountid} value={state.distributorname}>
                  {state.distributorname}
                </option>
              ))}
            </select> */}
            {startDate && endDate && (
              <FormControl
                component='fieldset'
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  borderRadius: '40px',
                  padding: '5px 10px',
                }}
              >
                <RadioGroup row onChange={handleRadioChange}>
                  <FormControlLabel
                    value='all'
                    control={<Radio sx={{ color: '#85c225' }} />}
                    label='Get all record for selected date'
                    labelPlacement='end'
                    sx={{
                      '& .MuiFormControlLabel-label': { color: '#85c225' },
                    }}
                  />
                </RadioGroup>
              </FormControl>
            )}
            <IconButton
              size='large'
              variant='contained'
              onClick={() => clearDateFilter()}
              disabled={!dateRange1 || loading}
            >
              <RefreshIcon fontSize='large' />
            </IconButton>
          </div>
          {loading && <OpaqueLoading />}
          <Button
            startIcon={<GetAppIcon />}
            size='large'
            onClick={() => exportData()}
            variant='contained'
          >
            Export
          </Button>
        </HeaderContainer>
        {/* </Stack>
          </Stack> */}

        <TableContainer>
          <TabsContainer>
            <StyledTabs
              variant='fullWidth'
              value={selectedTab}
              onChange={(event, value) => handleTabChange(value)}
              indicatorColor='primary'
              textColor='primary'
            >
              <Tab
                label='New Order'
                style={{ fontWeight: selectedTab === 0 ? 'bold' : 'normal' }}
              />
              <Tab
                label='Old Orders'
                style={{ fontWeight: selectedTab === 1 ? 'bold' : 'normal' }}
              />
            </StyledTabs>
            {renderContent()}
          </TabsContainer>
        </TableContainer>
      </StaffEditorPageContainer>
      {orderDetails && (
        <OrderDetailsModal
          data={Details}
          remarks={remark}
          distributorId={distributorId}
          id={id}
          onClose={() => setOrderDetails(false)}
          fetchData={() => fetchData()}
          selectedTab={selectedTab}
        />
      )}
    </>
  );
}
