import {
  Drawer,
  ListItemIcon,
  Box,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Fab,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import icons from '../icons/IconCollections';
import eventBus from '../../event-emitter/EventBus';

const isMobile = window.innerWidth < 900;

const Container = styled.div`
  position: relative;
`;

const Sidebar = () => {
  const [activeItem, setActiveItem] = useState(window.location.pathname);
  const [drawerOpen, setDrawerOpen] = useState(
    isMobile ? false : JSON.parse(localStorage.getItem('isDrawerOpen'))
  );

  const [hideFab, setHideFab] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1105) {
        setHideFab(true);
        setDrawerOpen(false);
      } else {
        setHideFab(false);
        setDrawerOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleActiveTab = (redirectedTo) => {
    setActiveItem(redirectedTo);
  };

  useEffect(() => {
    const pathname = window.location.pathname;
    handleActiveTab(pathname === '/' ? '/sfa-dashboard' : pathname);

    eventBus.on('CardClick', handleActiveTab);
    eventBus.on('modalOpened', handleFabIcon);

    return () => {
      eventBus.off('CardClick', handleActiveTab);
      eventBus.off('modalOpened', handleFabIcon);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem('isDrawerOpen', drawerOpen);
  }, [drawerOpen]);

  const handleFabIcon = (bool) => {
    setHideFab(bool);
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleItemClick = (link) => {
    setActiveItem(link);
  };

  return (
    <Container>
      <Drawer
        style={{
          width: drawerOpen ? '240px' : '80px',
          transition: 'all 0.2s',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        variant={isMobile ? 'temporary' : 'permanent'}
        sx={{
          width: drawerOpen ? '240px' : '80px',
          '& .MuiDrawer-paper': {
            padding: '10px',
            background: '#F6F6F6',
            width: drawerOpen ? '240px' : '80px',
            transition: 'all 0.2s',
          },
        }}
        anchor='left'
        open={drawerOpen}
        onClose={toggleDrawer}
      >
        <div>
          <NavLink
            onClick={() => handleItemClick('/sfa-dashboard')}
            to='/sfa-dashboard'
          >
            <div style={{ padding: 10 }}>
              <img
                src={
                  drawerOpen
                    ? 'iillogo.png'
                    : isMobile
                    ? 'iillogo.png'
                    : 'Frame.png'
                }
                alt='Logo'
                style={{
                  width: drawerOpen ? '180px' : '40px',
                  transition: 'all 0.2s',
                }}
              />
            </div>
          </NavLink>

          <List sx={{ mt: 2 }}>
            {icons.map((item) => {
              const isTitleSpecific = item.title === 'Add Target';
              return (
                <NavLink
                  to={item.link}
                  key={item.title}
                  style={{
                    textDecoration: 'none',
                    height: '45px',
                    display: isTitleSpecific ? 'none' : 'block',
                  }}
                  onClick={() => handleItemClick(item.link)}
                >
                  <ListItem
                    button
                    sx={{
                      backgroundColor:
                        activeItem === item.link ? '#79B835' : '#F6F6F6',
                      color: activeItem === item.link ? '#F6F6F6' : 'black',
                      '&:hover': {
                        backgroundColor:
                          activeItem === item.link ? '#79B835' : '#E6F7D2',
                      },
                    }}
                  >
                    <Tooltip title={item.title} placement='right'>
                      <Box display='flex' alignItems='center'>
                        <ListItemIcon
                          sx={{
                            color:
                              activeItem === item.link ? '#F6F6F6' : 'black',
                          }}
                        >
                          {activeItem === item.link ? item.iconW : item.iconB}
                        </ListItemIcon>
                        {drawerOpen && <ListItemText primary={item.title} />}
                      </Box>
                    </Tooltip>
                  </ListItem>
                </NavLink>
              );
            })}
          </List>
        </div>
      </Drawer>
      {!hideFab && (
        <Fab
          onClick={toggleDrawer}
          size='small'
          sx={{
            position: 'fixed',
            top: '10%',
            left: drawerOpen ? '220px' : '60px',
            transform: 'translateY(-50%)',
            zIndex: 99999,
            backgroundColor: '#79B835',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: '#6DA42D',
            },
          }}
        >
          {drawerOpen ? (
            <KeyboardArrowLeft sx={{ fontSize: '24px', color: '#FFFFFF' }} />
          ) : (
            <KeyboardArrowRight sx={{ fontSize: '24px', color: '#FFFFFF' }} />
          )}
        </Fab>
      )}
    </Container>
  );
};

export default Sidebar;
