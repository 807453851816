import { Button, Typography, IconButton } from '@mui/material';

import React, { useEffect, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Drawer from '../../components/common/drawer/drawer';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import axios from 'axios';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import styled from '@emotion/styled';
import {
  DataGrid,
  GridToolbarColumnsButton,
  getGridStringOperators,
} from '@mui/x-data-grid';
import { GridToolbarContainer } from '@mui/x-data-grid';
import { GridToolbarFilterButton } from '@mui/x-data-grid';
import { GridToolbarExport } from '@mui/x-data-grid';
import OpaqueLoading from '../../components/opaqueLoading/opaqueLoading';
import GetAppIcon from '@mui/icons-material/GetApp';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
} from '@mui/material';
const isMobile = window.innerWidth < 900;

const StaffEditorPageContainer = styled.div`
  // height: ${isMobile ? `auto` : `calc(100vh - 80px)`};
  width: 98%;
  margin: auto;
  background-color: white;
  padding: 10px 15px;
  ${isMobile &&
  `
      position: relative;
 
      `}
`;
const DataGridContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  // height: ${isMobile ? 'calc(100vh - 302px)' : 'calc(100vh - 215px)'};
  && .highlighted-row {
    background-color: #ffcccb !important;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? 'flex-start' : 'space-between'};
  margin-bottom: 10px;
  flex-direction: ${isMobile ? 'column' : 'row'};
`;

const TableContainer = styled.div`
  height: calc(
    100vh - ${isMobile ? '56px - 70px - 175.23px' : '140.5px - 20px - 52.77px'}
  );
  width: 100%;
  border: solid 1px lightGrey;
  border-radius: 8px;
  overflow-x: ${isMobile
    ? 'auto'
    : 'hidden'}; /* Add horizontal scroll for mobile */
`;

const StyledCardHeading = styled(Typography)`
  text-transform: none;
  letter-spacing: 0.5px;
`;

const CustomToolbar = ({ onAgreeDisagree }) => {
  const [selectedOption, setSelectedOption] = useState('agree');
  const handleRadioChange = (event) => {
    const value = event.target.value;
    onAgreeDisagree(value);
    setSelectedOption(value);
  };

  return (
    <GridToolbarContainer>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <FormControl
          component='fieldset'
          sx={{
            display: 'flex',
            flexDirection: 'row',
            borderRadius: '40px',
            padding: '5px 10px',
          }}
        >
          <RadioGroup row value={selectedOption} onChange={handleRadioChange}>
            <FormControlLabel
              value='agree'
              control={<Radio sx={{ color: '#85c225' }} />}
              label='Agree'
              labelPlacement='end'
              sx={{ '& .MuiFormControlLabel-label': { color: '#85c225' } }}
            />
            <FormControlLabel
              value='disagree'
              control={<Radio sx={{ color: '#85c225' }} />}
              label='Disagree'
              labelPlacement='end'
              sx={{ '& .MuiFormControlLabel-label': { color: '#85c225' } }}
            />
          </RadioGroup>
        </FormControl>
      </Box>
    </GridToolbarContainer>
  );
};

const quarters = {
  Q1: { start: '04-01', end: '06-30' },
  Q2: { start: '04-01', end: '09-30' },
  Q3: { start: '04-01', end: '12-31' },
  Q4: { start: '04-01', end: '03-31' },
};
const currentYear = 2024; // as of now its static but need to implement dyanamic
const initialStartDate =
  quarters.Q1.start &&
  dayjs(`${currentYear}-${quarters.Q1.start}`).format('YYYY/MM/DD');
const initialEndDate =
  quarters.Q1.end &&
  dayjs(`${currentYear}-${quarters.Q1.end}`).format('YYYY/MM/DD');

const FinanceManagement = (props) => {
  const [agreeDisagree, setAgreeDisagree] = useState('agree');
  const [isloader, setIsloader] = React.useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [distributorData, setDistributorData] = useState([]);
  const [view, setView] = useState(0);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [startDate1, setStartDate1] = useState(initialStartDate);
  const [endDate1, setEndDate1] = useState(initialEndDate);
  const [dateRange1, setDateRange1] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [selectedSFID, setSelectedSFID] = useState('');
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 12,
  });
  const [searchValue, setSearchValue] = useState('');
  const [selectedQuarter, setSelectedQuarter] = useState('Q1');

  const downloadAndZipFiles = async (urls) => {
    const zip = new JSZip();

    try {
      // Fetch each file
      for (const url of urls) {
        const {
          file_url__c = '',
          name,
          sap_id__c,
          start_date__c,
          end_date__c,
        } = url;
        const type = file_url__c.split('.').pop();
        const fileName =
          name +
          '_' +
          start_date__c +
          '_' +
          end_date__c +
          '_' +
          sap_id__c +
          '.' +
          type;
        const response = await axios.get(file_url__c, {
          responseType: 'arraybuffer',
        });

        // Add file to the zip
        zip.file(fileName, response.data);
      }

      // Generate zip file
      const content = await zip.generateAsync({ type: 'blob' });

      // Trigger download
      saveAs(content, 'files.zip');
    } catch (error) {
      console.error('Error downloading files', error);
    }
  };

  const exportData = async () => {
    try {
      console.log(selectedRowIds);
      if (selectedRowIds.length === 0) {
        window.NotificationUtils.showWarning(
          'Please select checkbox to export data'
        );

        return;
      }
      setIsloader(true);
      const selectedRows = rows.filter((row) =>
        selectedRowIds.includes(row.id)
      );
      const dataToExport = selectedRows.map((row) => ({
        start_date__c: row.start_date__c,
        end_date__c: row.end_date__c,
        distributorsfid: row.sfid,
      }));

      let results = await window.Platform.database.exportBalanceConfirmation({
        distributorDetails: dataToExport,
      });

      const urlData = [];

      for (let url of results.data) {
        const { file_url__c = '' } = url;
        urlData.push(file_url__c);
      }
      downloadAndZipFiles(results.data);
      setIsloader(false);
    } catch (err) {
      setIsloader(false);
      console.log('error', err);
      window.NotificationUtils.showError(
        'Error While Recieving Data Please Wait and try again'
      );
    }
  };
  const drawerWidth = '240px';
  const quarters = {
    Q1: { start: '04-01', end: '06-30' },
    Q2: { start: '04-01', end: '09-30' },
    Q3: { start: '04-01', end: '12-31' },
    Q4: { start: '04-01', end: '03-31' },
  };
  const handleQuarterChange = (quarter) => {
    if (!quarter) return;
    const currentYear = 2024; // for now its static but need to implement dynamic
    const selectedQuarter = quarters[quarter];
    setSelectedQuarter(quarter);
    const newStartDate =
      quarter === 'Q4'
        ? dayjs(`${currentYear}-${selectedQuarter.start}`).format('YYYY/MM/DD')
        : dayjs(`${currentYear}-${selectedQuarter.start}`).format('YYYY/MM/DD');
    const newEndDate =
      quarter === 'Q4'
        ? dayjs(`${currentYear + 1}-${selectedQuarter.end}`).format(
            'YYYY/MM/DD'
          )
        : dayjs(`${currentYear}-${selectedQuarter.end}`).format('YYYY/MM/DD');
    setStartDate1(newStartDate);
    setEndDate1(newEndDate);
  };
  const handleStateChange = (e) => {
    const selectedValue = e.target.value;

    // Find the selected state object
    const state = states.find((state) => state.name__c === selectedValue);

    if (state) {
      setSelectedState(state.name__c); // Update selected state name
      setSelectedSFID(state.sfid);
    }
  };

  const getCategoryData = async () => {
    try {
      setIsloader(true);
      const response = await window.Platform.database.getDistributorDetails({
        searchField: searchValue,
        pageNumber: paginationModel.page + 1,
        startDate: startDate1,
        endDate: endDate1,
        agree__c: agreeDisagree === 'agree',
        state__c: selectedSFID,
      });
      setIsloader(false);
      if (response.data) {
        setDistributorData(response.data.data);
        setRowCount(response.data.count[0].count);
      }
    } catch (error) {
      setIsloader(false);
      console.error(error);
    }
  };
  const getStateData = async () => {
    try {
      const response = await window.Platform.database.getStates();

      setStates(response.data);

      if (!response.hasError) {
        //setCategoryData([...categoryData, ...response.data]);
      }
    } catch (error) {
      //setIsloader(false);
      console.error(error);
    }
  };
  useEffect(() => {
    console.log('Distributor data updated:', distributorData);
  }, [distributorData]);
  useEffect(() => {
    console.log('selected row ids:', selectedRowIds);
  }, [selectedRowIds]);
  useEffect(() => {
    console.log('states updated', states);
  }, [states]);
  useEffect(() => {
    getCategoryData();
    getStateData();
  }, [
    paginationModel.page,
    searchValue,
    startDate1,
    endDate1,
    agreeDisagree,
    selectedState,
  ]);
  const handleSearch = (value) => {
    setSearchValue(value); // Update state with the entered value
    console.log('Search Value:', value); // Log or handle the search logic here
  };

  const handleColumns = () => {
    const columns = [
      // {
      //   field: "id",
      //   headerName: "ID",
      //   width: 100,
      // },
      {
        field: 'state_name',
        headerName: 'State',
        width: 150,
      },
      {
        field: 'sap_id__c',
        headerName: 'Party Id',
        width: 200,
      },
      {
        field: 'firm_name__c',
        headerName: 'Party Name',
        width: 200,
      },
      {
        field: 'city__c',
        headerName: 'City',
        width: 150,
      },
      {
        field: 'pincode__c',
        headerName: 'Pincode',
        width: 150,
      },
      {
        field: 'mobile__c',
        headerName: 'Mobile',
        width: 200,
      },
      {
        field: 'start_date__c',
        headerName: 'Start Date',
        width: 200,
      },
      {
        field: 'end_date__c',
        headerName: 'End date',
        width: 200,
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 200,
      },
    ];

    return columns;
  };
  const initialDrawerState = JSON.parse(localStorage.getItem('isDrawerOpen'));

  // JSON.parse(localStorage.getItem('isDrawerOpen'))

  const [drawerOpen, setDrawerOpen] = useState(
    isMobile ? false : initialDrawerState
  );

  const rows = distributorData.map((item, index) => ({
    id: index + 1,
    state_name: item.state_name,
    sap_id__c: item.sap_id__c,
    sfid: item.sfid,
    city__c: item.city__c,
    pincode__c: item.pincode__c,
    firm_name__c: item.firm_name__c,
    mobile__c: item.mobile__c,
    start_date__c: item.start_date__c,
    end_date__c: item.end_date__c,
    status: item.downloaded__c ? 'Downloaded' : 'Not Downloaded',
  }));
  useEffect(() => {
    localStorage.setItem('isDrawerOpen', drawerOpen);
  }, [drawerOpen]);

  const clearDateFilter = async () => {
    setSearchValue('');
    setSelectedState('');
    setSelectedSFID('');
    setSelectedQuarter('Q1');
    handleQuarterChange('Q1');
  };

  return (
    <>
      <StaffEditorPageContainer>
        <HeaderContainer>
          <Typography variant='h5'>Distributor Mapping List</Typography>
        </HeaderContainer>

        <HeaderContainer>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              gap: '20px',
              paddingTop: '4px',
              marginBottom: '10px',
            }}
          >
            <input
              type='text'
              placeholder='Search...'
              style={{
                flex: '1',
                padding: '8px',
                fontSize: '16px',
                border: '1px solid #ccc',
                borderRadius: '4px',
              }}
              value={searchValue}
              onChange={(e) => handleSearch(e.target.value)}
            />
            <select
              style={{
                padding: '8px',
                fontSize: '16px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                background: '#fff',
              }}
              value={selectedState}
              onChange={(e) => handleStateChange(e)}
            >
              <option value='' disabled selected>
                Select State
              </option>
              {states.map((state) => (
                <option key={state.sfid} value={state.name__c}>
                  {state.name__c}
                </option>
              ))}
            </select>

            <IconButton
              size='large'
              variant='contained'
              disabled={(!selectedState && !searchValue) || isloader}
              onClick={() => clearDateFilter()}
            >
              <RefreshIcon fontSize='large' />
            </IconButton>
            {isloader && <OpaqueLoading />}

            <Button
              startIcon={<GetAppIcon />}
              size='large'
              style={{
                height: '50px',
                padding: '8px 16px',
                fontSize: '16px',
                borderRadius: '4px',
              }}
              onClick={() => exportData()}
              variant='contained'
            >
              Download
            </Button>
          </div>
        </HeaderContainer>
        <HeaderContainer>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              gap: '20px',
              marginBottom: '10px',
            }}
          >
            <select
              style={{
                flex: '1',
                padding: '8px',
                fontSize: '16px',
                border: '1px solid #ccc',
                borderRadius: '4px',
              }}
              value={selectedQuarter}
              onChange={(e) => handleQuarterChange(e.target.value)}
            >
              <option value='Q1'>Quarter 1 (April to June)</option>
              <option value='Q2'>Quarter 2 (July to September)</option>
              <option value='Q3'>Quarter 3 (October to December)</option>
              <option value='Q4'>Quarter 4 (January to March)</option>
            </select>

            <input
              type='text'
              value={startDate1 || ''}
              placeholder='Start Date'
              style={{
                padding: '8px',
                fontSize: '16px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                backgroundColor: '#f5f5f5',
              }}
              disabled
            />

            <input
              type='text'
              value={endDate1 || ''}
              placeholder='End Date'
              style={{
                padding: '8px',
                fontSize: '16px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                backgroundColor: '#f5f5f5',
              }}
              disabled
            />
          </div>
        </HeaderContainer>

        {view === 0 && (
          <DataGridContainer
            style={{ height: rows.length > 0 ? 'auto' : '300px' }}
          >
            <DataGrid
              paginationModel={paginationModel}
              paginationMode='server'
              onPaginationModelChange={setPaginationModel}
              rows={rows}
              columns={handleColumns()}
              checkboxSelection
              // onRowClick={(params) => {
              //   console.log("RowClick", params);
              //   setSelectedRow(params.row);
              //   setView(1);
              // }}
              onRowSelectionModelChange={(selectedRowIndices) => {
                console.log('Selected Row Indices:', selectedRowIndices);

                setSelectedRowIds(selectedRowIndices);
              }}
              disableSelectionOnClick
              disableRowSelectionOnClick
              rowCount={rowCount}
              components={{
                Toolbar: CustomToolbar,
              }}
              componentsProps={{
                toolbar: {
                  onAgreeDisagree: setAgreeDisagree,
                },
              }}
            />
          </DataGridContainer>
        )}
      </StaffEditorPageContainer>
    </>
  );
};
export default FinanceManagement;
