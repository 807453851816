import React, { useContext, useState } from 'react';
import { TextField } from '@mui/material';
import { Button } from 'reactstrap';
import { MuiOtpInput } from 'mui-one-time-password-input';
import styled from 'styled-components';

import loginLogo from '../../assets/login/images/login.png';
import { AuthContext } from '../../components/contextAPI/ContextAPI';
import { LOCAL_STORAGE_KEYS } from '../../constants';
import OpaqueLoading from '../../components/opaqueLoading/opaqueLoading';
import { useNavigate } from 'react-router-dom';

const CenterContent = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
`;

const CenterRow = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Logo = styled.img`
  height: 100%;
`;

const loginContainer = {
  width: '100%',
  height: '100%',
  background: '#FFFFFF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const section1 = {
  width: '400px',
  textAlign: 'left',
};

const section2 = {
  width: '400px',
  textAlign: 'left',
};

const heading = {
  fontSize: '36px',
  fontWeight: '600',
  lineHeight: '45.54px',
  color: '#464646',
  marginBottom: '10px',
};

const subHeading = {
  fontSize: '20px',
  fontWeight: '400',
  lineHeight: '30.36px',
  color: '#B9B9B9',
};

const button = {
  width: '100%',
  marginTop: '25px',
  background: '#79B835',
};

const CodeLink = styled.a`
  display: block;
  fontsize: 18px;
  font-weight: 500;
  line-height: 22.77px;
  width: fit-content;
  color: #464646;
  margin-top: 25px;
`;

const Login = (props) => {
  const { auth, setAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  const [password, setPassword] = React.useState('');
  const [username, setUserName] = React.useState();
  const [pageNumber, setPageNumber] = useState('1');
  const [loading, setLoading] = useState(false);

  let contextState = { ...auth };

  const handleOTPRequest = async (type) => {
    let mobile = username;
    if (mobile.length < 10 || mobile.length > 10) {
      window.NotificationUtils.showError(
        'Wrong Credentials, Please Enter 10 digit Mobile number'
      );
    } else {
      try {
        setLoading(true);
        const data = {
          username,
          signatureId: 't22jBsJWGmZ',
          fromDesktop: true,
        };

        const response = await window.Platform.database.sendOTP(data);

        if (response.hasError) {
          window.NotificationUtils.showError(response.message);
        }
        localStorage.clear();

        if (!response.hasError) {
          if (type !== 'resend') {
            window.NotificationUtils.showSuccess(
              'Enter the OTP Received on Your Mobile'
            );
          }
        }
        setPageNumber('2');
        setLoading(false);
      } catch (error) {
        console.error(error);
        window.NotificationUtils.showError(
          'Something went wrong, please try again '
        );
        setLoading(false);
      }
    }
  };

  const handleLogin = async () => {
    const data = {
      username,
      otp: password,
      fromDesktop: true,
    };

    if (password.length !== 6) {
      window.NotificationUtils.showWarning('Please fill the Credentials');
    } else {
      try {
        setLoading(true);
        contextState.loading = true;
        const userResults = await window.Platform.database.verifyOTP(data);
        const { data: userRecord } = userResults;
        const { userDetails, sessionToken } = userRecord;
        userDetails.sessionToken = sessionToken;
        window.localStorage.setItem(
          LOCAL_STORAGE_KEYS.STORED_USER_DATA,
          JSON.stringify(userResults)
        );
        if (userDetails?.plant_master__c) {
          window.localStorage.setItem(
            'plantCode',
            userDetails?.plant_master__c
          );
        }
        setAuth(userRecord);
        contextState.loggedIn = true;
        contextState.token = userRecord.sessionToken;
        contextState.user = userDetails;
        contextState.email = userDetails.email__c;
        setAuth(contextState);
        if (userDetails) {
          props.onAuthUserChanged && props.onAuthUserChanged(userDetails);
        }
        window.NotificationUtils.showSuccess('Logged In Successfully');
        if (userDetails.profile__c === 'Depot Manager') {
          navigate('/orderreport');
        } else if (userDetails.profile__c === 'Finance') {
          navigate('/financialreport');
        } else if (userDetails.profile__c === 'RM') {
          navigate('/sfa-dashboard');
        } else if (!userDetails.profile__c) {
          navigate('/login');
          localStorage.clear();
        }
        setLoading(false);
      } catch (error) {
        if (error && error.code === 101) {
          window.NotificationUtils.showError('Invalid OTP');
        } else {
          window.NotificationUtils.showError('Something Went Wrong');
        }
      } finally {
        contextState.loading = false;
        setLoading(false);
      }
    }
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    const sanitizedValue = value.replace(/[^0-9]/g, '');
    setUserName(sanitizedValue);
  };

  const handleChange = (value) => {
    setPassword(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  const renderLoginCard = () => {
    return (
      <div style={loginContainer}>
        {pageNumber === '1' && (
          <section style={section1}>
            <header>
              <h2 style={heading}>Welcome</h2>
              <p style={subHeading}>Log in to continue</p>
            </header>

            <main>
              <TextField
                margin='normal'
                variant='standard'
                fullWidth
                InputProps={{
                  inputProps: {
                    pattern: '[0-9]*',
                    maxLength: 10,
                  },
                }}
                value={username}
                label='Enter Mobile Number'
                autoFocus
                onKeyDown={(e) => {
                  if (e && e.key && e.key === 'Enter') {
                    handleOTPRequest('otp');
                  }
                }}
                onChange={handleInputChange}
              />

              <Button
                variant='contained'
                onClick={(e) => handleOTPRequest('otp')}
                disabled={loading || !username}
                style={button}
              >
                Next
              </Button>
            </main>
          </section>
        )}
        {pageNumber === '2' && (
          <section style={section2}>
            <header>
              <h2 style={heading}>OTP Verification Code</h2>
              <p style={subHeading}>
                Enter the 6 digit number that we sent to {username}
              </p>
            </header>

            <main>
              <MuiOtpInput
                value={password}
                onChange={handleChange}
                length={6}
                sx={{ margin: '25px 0 0 0' }}
                onKeyDown={handleKeyDown}
                autoFocus={true}
                validateChar={(val) => !isNaN(val)}
              />

              <CodeLink
                href='javascript:void(null);'
                underline='hover'
                onClick={(e) => handleOTPRequest('resend')}
                disabled={loading}
                style={{
                  color: loading ? '#b9b9b9' : '#464646',
                  cursor: loading ? 'not-allowed' : 'pointer',
                }}
              >
                Resend Code
              </CodeLink>

              <Button
                variant='contained'
                onClick={handleLogin}
                disabled={loading || password?.length < 6}
                style={button}
              >
                Submit
              </Button>
            </main>
          </section>
        )}
      </div>
    );
  };

  const renderLogo = () => {
    return <Logo src={loginLogo} />;
  };

  return (
    <CenterContent>
      <CenterRow>
        {loading && <OpaqueLoading />}
        {renderLogo()}
        {renderLoginCard()}
      </CenterRow>
    </CenterContent>
  );
};

export default Login;
