import { Grid, Card, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Link from '@mui/material/Link';

import Sidebar from '../../../components/common/sidebar/Sidebar';
import pdfIcon from '../../../assets/dashboard/images/pdf.png';
import linkedin from '../../../assets/social-media/linkedin.svg';
import youtube from '../../../assets/social-media/youtube.svg';
import twitter from '../../../assets/social-media/twitter.svg';
import instagram from '../../../assets/social-media/instagram.svg';
import facebook from '../../../assets/social-media/facebook.svg';
import banner1 from '../../../assets/dashboard/images/banner1.jpg';
import banner2 from '../../../assets/dashboard/images/banner2.jpg';
import banner3 from '../../../assets/dashboard/images/banner3.jpg';
import banner4 from '../../../assets/dashboard/images/banner4.jpg';
import icons from '../../../components/common/icons/IconCollections';
import eventBus from '../../../components/event-emitter/EventBus';
import CustomHeader from '../../../components/common/header/CustomHeader';

const heading = {
  fontSize: '28px',
  fontWeight: '600',
  color: '#464646',
  textAlign: 'left',
};

const HeaderImage = styled.div`
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 233px;
`;

const mainContainer = {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  alignItems: 'stretch',
};

const subContainer1 = {
  marginTop: '50px',
  width: '55%',
};

const subContainer2 = {
  marginTop: '35px',
  width: '40%',
};

const navigateLinks = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  gap: '20px',
};

const linksDiv = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flex: '1 1 30%',
  borderRadius: '5px',
  padding: '13px',
  boxShadow: '0px 4px 4px 0px #0000001A',
  cursor: 'pointer',
};

const linkTitle = { color: '#000', marginLeft: '10px' };

const AnnouncementLink = styled.a`
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 5px;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  transition: 0.3s all;

  &:hover {
    color: #fff;
    font-weight: 600;
    text-decoration: underline;
  }
`;

const announcementCard = {
  padding: 3,
  m: 2,
  borderRadius: '5px',
  backgroundColor: '#79B835',
  textAlign: 'left',
  width: '100%',
  height: '504px',
  overflowY: 'auto',
  position: 'relative',
  boxShadow: '0px 4px 4px 0px #0000001A',
};

const announcementHeading = {
  color: '#FFFFFF',
  fontSize: '24px',
  fontWeight: '500',
  lineHeight: '30.36px',
};

const announcementTitle = {
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '20.24px',
  mb: 1,
};

const announcementText = {
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '17.71px',
  mt: 1,
};

const announcementCustomCard = {
  margin: '15px 0px',
  padding: '15px 20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const barChartText = {
  fontSize: '24px',
  fontWeight: '500',
  lineHeight: '30.36px',
};

const barChartInfoContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  m: 1,
  mt: 2,
};

const chartSubTitle = {
  display: 'flex',
  alignItems: 'center',
};

const SliderButtonContainer = styled.div`
  width: 100%;
  position: absolute;
  margin: 5px 0 0 0;
  text-align: center;
`;

const dot = {
  cursor: 'pointer',
  height: '10px',
  width: '10px',
  margin: '0 2px',
  backgroundColor: '#bbb',
  borderRadius: '50%',
  display: 'inline-block',
  transition: 'background-color 0.6s ease',
};

const fadeIn = {
  opacity: '1',
  transition: 'opacity 0.6s ease-in',
};

const fadeOut = {
  opacity: '0',
  transition: 'opacity 0.6s ease-out',
};

const Dashboard = () => {
  const navigate = useNavigate();

  const [announcement, setAnnouncement] = useState([]);
  const [activityData, setActivityData] = useState([]);
  const [announcementCount, setAnnouncementCount] = useState(0);
  const [financialYearData, setFinancialYearData] = useState({});

  const [currentImage, setCurrentImage] = useState(1);
  const [fading, setFading] = useState(false);

  const [counter, setCounter] = useState(1);
  const [auth, setAuth] = useState({});

  const [chart, setChart] = React.useState({
    series: [
      {
        data: [
          {
            x: 'Completed',
            y: activityData.completed,
            fillColor: '#79B835',
          },
          {
            x: 'In Progress',
            y: activityData.in_progress,
            fillColor: '#FEDD37',
          },
          {
            x: 'Not Started',
            y: activityData.not_started,
            fillColor: '#ED1C24',
          },
        ],
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 250,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 7,
          columnWidth: '100%',
          barHeight: '25%',
          distributed: true,
          rangeBarOverlap: true,
          rangeBarGroupRows: false,
          hideZeroBarsWhenGrouped: false,
          isDumbbell: false,
          dumbbellColors: undefined,
          isFunnel: false,
          isFunnel3d: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: ['Completed', 'In Progress', 'Not Started'],
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          var data =
            w.globals.initialSeries[seriesIndex]?.data[dataPointIndex].y;

          return `<div style="padding: 10px; background-color: #fff; border-radius: 5px; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);">
                    ${data}
                  </div>`;
        },
      },
      stroke: {
        show: false,
      },
      legend: {
        show: false,
      },
      grid: {
        show: true,
        borderColor: '#90A4AE',
        strokeDashArray: 0,
        position: 'back',
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
        row: {
          colors: undefined,
          opacity: 0.5,
        },
        column: {
          colors: undefined,
          opacity: 0.5,
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
    },
  });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('storedUserData'));
    if (user) {
      setAuth(user);
      getAnnouncements();
      getBarGraphData();
    } else {
      window.location.reload();
    }
    getFinancialYearsWithRange();

    const interval = setInterval(() => {
      setCounter((prevCounter) => {
        const nextCounter = prevCounter + 1 > 4 ? 1 : prevCounter + 1;
        currentSlide(nextCounter);
        return nextCounter;
      });
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setChart((prevState) => ({
      ...prevState,
      series: [
        {
          data: [
            {
              x: 'Completed',
              y: activityData.completed,
              fillColor: '#79B835',
            },
            {
              x: 'In Progress',
              y: activityData.in_progress,
              fillColor: '#FEDD37',
            },
            {
              x: 'Not Started',
              y: activityData.not_started,
              fillColor: '#ED1C24',
            },
          ],
        },
      ],
    }));
  }, [activityData]);

  function getFinancialYearsWithRange() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // getMonth() is zero-based

    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const monthName = months[currentMonth - 1];

    let currentFinancialYearStart, currentFinancialYearEnd;

    // If current month is April or later, financial year starts in the current year
    if (currentMonth >= 4) {
      currentFinancialYearStart = new Date(currentYear, 3, 1); // April 1st of current year
      currentFinancialYearEnd = new Date(currentYear + 1, 2, 31); // March 31st of next year
    } else {
      currentFinancialYearStart = new Date(currentYear - 1, 3, 1); // April 1st of last year
      currentFinancialYearEnd = new Date(currentYear, 2, 31); // March 31st of current year
    }

    // Function to format date as YYYY-MM-DD
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero for month
      const day = String(date.getDate()).padStart(2, '0'); // Add leading zero for day
      return `${year}-${month}-${day}`;
    };

    // Function to calculate the previous financial year
    const getPreviousFinancialYear = (start, end) => {
      const prevStart = new Date(start.getFullYear() - 1, 3, 1); // Previous April 1st
      const prevEnd = new Date(end.getFullYear() - 1, 2, 31); // Previous March 31st
      return {
        financialYear: `${prevStart.getFullYear()}`,
        range: {
          start: formatDate(prevStart),
          end: formatDate(prevEnd),
        },
      };
    };

    // Current Financial Year
    const currentFinancialYear = {
      financialYear: `${currentFinancialYearStart.getFullYear()}`,
      financialMonth: monthName,
      range: {
        start: formatDate(currentFinancialYearStart),
        end: formatDate(currentFinancialYearEnd),
      },
    };

    // Previous Financial Year
    const previousFinancialYear = getPreviousFinancialYear(
      currentFinancialYearStart,
      currentFinancialYearEnd
    );

    // Financial Year Before Last
    const secondPreviousFinancialYear = getPreviousFinancialYear(
      new Date(previousFinancialYear.range.start),
      new Date(previousFinancialYear.range.end)
    );

    setFinancialYearData((prev) => {
      return {
        ...prev,
        currentFinancialYear,
        previousFinancialYear,
        secondPreviousFinancialYear,
      };
    });
  }

  const getBarGraphData = async () => {
    try {
      const result = await window.Platform.database.getActivityDashboard();
      setActivityData(result.data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const getAnnouncements = async () => {
    try {
      const payload = {
        fromDms: false,
        fromWeb: true,
        pageNumber: 1,
      };
      const result = await window.Platform.database.getAnnouncements(payload);
      setAnnouncementCount(result?.data?.length ?? 0);

      const filteredResult =
        result?.data?.length > 3 ? result?.data?.slice(0, 3) : result?.data;

      const modifiedResult = filteredResult.map((announcement) => {
        const fileType = announcement.file_type__c;
        const youtubeUrl = announcement.url__c;

        if (fileType === 'Youtube') {
          const regExp =
            /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
          const videoId = regExp.exec(youtubeUrl)[3];

          const youtubeThumbnailUrl = `https://i3.ytimg.com/vi/${videoId}/hqdefault.jpg`;
          announcement.thumbnail_url__c = youtubeThumbnailUrl;
        } else {
          announcement.thumbnail_url__c = pdfIcon;
        }

        return announcement;
      });
      setAnnouncement(modifiedResult);
    } catch (error) {
      throw error;
    }
  };

  const handleAnnouncementNavigate = () => {
    navigate('/announcements');
  };

  const currentSlide = (newIndex) => {
    setFading(true);
    setTimeout(() => {
      setCurrentImage(newIndex);
      setFading(false);
    }, 200);
  };

  const navigateRedirect = (card) => {
    eventBus.emit('CardClick', card.link);
    navigate(card.link, { state: card.title === 'Add Target' ? true : false });
  };

  return (
    <div style={{ display: 'flex', width: '100%', backgroundColor: '#F6F6F6' }}>
      {auth && <Sidebar />}
      <div style={{ width: '100%' }}>
        {auth && <CustomHeader />}
        <Box p={4}>
          <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
            justifyContent='space-between'
            mb={2}
          >
            <Typography variant='h5' sx={heading}>
              {auth?.name__c ? auth?.name__c : ''}
              <Typography
                variant='p'
                sx={{
                  color: '#777',
                  fontSize: '14px',
                  margin: 0,
                  display: 'block',
                }}
              >
                {/* ({auth?.user?.sap_id__c ? auth?.user?.sap_id__c : ''}) */}
              </Typography>
            </Typography>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '10px',
              }}
            >
              <Link
                sx={{
                  transition: '0.7s all',
                  '&:hover': {
                    transform: 'scale(1.1)',
                  },
                }}
                href='https://www.facebook.com/InsecticidesIndia'
                target='_blank'
              >
                <img width={30} height={30} src={facebook} alt='Social Media' />
              </Link>
              <Link
                sx={{
                  transition: '0.7s all',
                  '&:hover': {
                    transform: 'scale(1.1)',
                  },
                }}
                href='https://www.instagram.com/insecticidesindialtd'
                target='_blank'
              >
                <img
                  width={30}
                  height={30}
                  src={instagram}
                  alt='Social Media'
                />
              </Link>
              <Link
                sx={{
                  transition: '0.7s all',
                  '&:hover': {
                    transform: 'scale(1.1)',
                  },
                }}
                href='https://x.com/insecticidesltd'
                target='_blank'
              >
                <img width={30} height={30} src={twitter} alt='Social Media' />
              </Link>
              <Link
                sx={{
                  transition: '0.7s all',
                  '&:hover': {
                    transform: 'scale(1.1)',
                  },
                }}
                href='https://linkedin.com/company/insecticidesindia'
                target='_blank'
              >
                <img width={30} height={30} src={linkedin} alt='Social Media' />
              </Link>
              <Link
                sx={{
                  transition: '0.7s all',
                  '&:hover': {
                    transform: 'scale(1.1)',
                  },
                }}
                href='https://www.youtube.com/InsecticidesIndiaLtd'
                target='_blank'
              >
                <img width={30} height={30} src={youtube} alt='Social Media' />
              </Link>
            </div>
          </Box>
          <Grid container>
            <Box
              sx={{
                width: '100%',
                margin: '0 auto',
              }}
            >
              <div style={{ position: 'relative' }}>
                {currentImage === 1 ? (
                  <HeaderImage
                    style={fading ? fadeOut : fadeIn}
                    src={banner1}
                  ></HeaderImage>
                ) : currentImage === 2 ? (
                  <HeaderImage
                    style={fading ? fadeOut : fadeIn}
                    src={banner2}
                  ></HeaderImage>
                ) : currentImage === 3 ? (
                  <HeaderImage
                    style={fading ? fadeOut : fadeIn}
                    src={banner3}
                  ></HeaderImage>
                ) : (
                  <HeaderImage
                    style={fading ? fadeOut : fadeIn}
                    src={banner4}
                  ></HeaderImage>
                )}
                <SliderButtonContainer>
                  <div
                    style={{
                      ...dot,
                      backgroundColor: currentImage === 1 ? '#717171' : '#bbb',
                    }}
                    onClick={() => currentSlide(1)}
                  ></div>
                  <div
                    style={{
                      ...dot,
                      backgroundColor: currentImage === 2 ? '#717171' : '#bbb',
                    }}
                    onClick={() => currentSlide(2)}
                  ></div>
                  <div
                    style={{
                      ...dot,
                      backgroundColor: currentImage === 3 ? '#717171' : '#bbb',
                    }}
                    onClick={() => currentSlide(3)}
                  ></div>
                  <div
                    style={{
                      ...dot,
                      backgroundColor: currentImage === 4 ? '#717171' : '#bbb',
                    }}
                    onClick={() => currentSlide(4)}
                  ></div>
                </SliderButtonContainer>
              </div>
            </Box>
            <div style={mainContainer}>
              <div style={subContainer1}>
                <div style={navigateLinks}>
                  {icons.map((card) => {
                    const isTitleSpecific = card.title === 'SFA Dashboard';
                    return (
                      <Box
                        sx={{
                          ...linksDiv,
                          transition: '0.7s all',
                          backgroundColor: '#FFF',
                          display: isTitleSpecific ? 'none' : 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          boxShadow: '4px 4px 4px 0px #0000001A',
                          '&:hover': {
                            transform: 'scale(1.02)',
                          },
                        }}
                        onClick={() => navigateRedirect(card)}
                        key={card.title}
                      >
                        <span>{card.iconG}</span>
                        <span style={linkTitle}>{card.title}</span>
                      </Box>
                    );
                  })}
                </div>
                <Card
                  sx={{
                    mt: 3,
                    borderRadius: '5px',
                    backgroundColor: '#FFF',
                    boxShadow: '4px 4px 4px 0px #0000001A',
                  }}
                >
                  <Box sx={{ padding: 2, width: '100%' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        m: 1,
                      }}
                    >
                      <Typography sx={barChartText} variant='subtitle1'>
                        Events
                      </Typography>
                      <Typography sx={barChartText} variant='subtitle2'>
                        {Object.keys(financialYearData).length > 0 &&
                          financialYearData.currentFinancialYear.financialMonth.toUpperCase() +
                            ' ' +
                            financialYearData.currentFinancialYear
                              .financialYear}
                      </Typography>
                    </Box>
                    <Box sx={barChartInfoContainer}>
                      <Box sx={chartSubTitle}>
                        <Box
                          sx={{
                            width: 15,
                            height: 15,
                            backgroundColor: '#ED1C24',
                            mr: 1,
                            borderRadius: '100%',
                          }}
                        />
                        <Typography variant='body1'>Not Started</Typography>
                      </Box>
                      <Box sx={chartSubTitle}>
                        <Box
                          sx={{
                            width: 15,
                            height: 15,
                            backgroundColor: '#FEDD37',
                            mr: 1,
                            borderRadius: '100%',
                          }}
                        />
                        <Typography variant='body1'>In Progress</Typography>
                      </Box>
                      <Box sx={chartSubTitle}>
                        <Box
                          sx={{
                            width: 15,
                            height: 15,
                            backgroundColor: '#79B835',
                            mr: 1,
                            borderRadius: '100%',
                          }}
                        />
                        <Typography variant='body1'>Completed</Typography>
                      </Box>
                    </Box>
                    <div>
                      <div id='chart'>
                        <ReactApexChart
                          options={chart.options}
                          series={chart.series}
                          type='bar'
                          height={300}
                        />
                      </div>
                    </div>
                  </Box>
                </Card>
              </div>
              <div style={subContainer2}>
                <Card variant='outlined' sx={announcementCard}>
                  <Typography
                    sx={announcementHeading}
                    variant='h6'
                    gutterBottom
                  >
                    Announcements
                  </Typography>
                  {announcement.map((announcement, index) => (
                    <Card key={index} style={announcementCustomCard}>
                      <div>
                        <Typography sx={announcementTitle} variant='body1'>
                          {announcement.name}
                        </Typography>
                        <Typography
                          variant='caption'
                          color='text.secondary'
                          sx={announcementText}
                        >
                          {announcement.createddate}
                        </Typography>
                        <Typography
                          variant='body2'
                          color='text.secondary'
                          paragraph
                          sx={announcementText}
                        >
                          {announcement.description__c}
                        </Typography>
                      </div>
                      <Link
                        sx={{
                          transition: '0.7s all',
                          '&:hover': {
                            transform: 'scale(1.1)',
                          },
                        }}
                        href={announcement.url__c}
                        target='_blank'
                      >
                        <img
                          src={announcement.thumbnail_url__c}
                          height={80}
                          width={80}
                          alt='Announcement Thumbnail'
                        />
                      </Link>
                    </Card>
                  ))}
                  {announcementCount > 0 && (
                    <AnnouncementLink
                      href='javascript:;'
                      onClick={handleAnnouncementNavigate}
                    >
                      view all...
                    </AnnouncementLink>
                  )}
                </Card>
              </div>
            </div>
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default Dashboard;
