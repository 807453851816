import Login from '../views/Login/index.js';
import Dashboard from '../views/Dashboard/index.js';
import CategoryPage from '../views/categoryManagement/index.js';
import FarmerManagement from '../views/farmerManagement/index.js';
import RetailerManagement from '../views/retailerManagement/index.js';
import DistributorManagement from '../views/distributorManagement/index.js';
import AgriExpertManagement from '../views/agriExpertManagement/index.js';
import FarmerVisit from '../views/farmerVisits/index.js';
import RetailerVisit from '../views/retailerVisits/index.js';
import DistributorVisit from '../views/distributorVisits/index.js';
import VanCampaign from '../views/vanCampaign/index.js';
import SpotDemo from '../views/spotDemo/index.js';
import NormalDemo from '../views/normalDemo/index.js';
import LpdDemoActivity from '../views/lpdDemoActivity/index.js';
import FarmerMeet from '../views/farmerMeet/index.js';
import KvkVisit from '../views/kvkVisit/index.js';
import KrishiMela from '../views/krishiMela/index.js';
import FeedbackReport from '../views/feedbackReport/index.js';
import TestDashboard from '../views/testDashboard/index.js';
import MappingManager from '../views/mappingManagement/index.js';
import VisitManager from '../views/visitManagement/index.js';
import EventManager from '../views/eventManagement/index.js';
import OrderManagement from '../views/orderManagement/index.js';
import Consolidated from '../views/consolidated/index.js';
import Testing from '../views/testing/index.js';
import AgriExpertVisit from '../views/agriExpertVisit/index.js';
import UserManagement from '../views/userManagement/index.js';
import FinanceManagement from '../views/financeManagement/index.js';
import SfaDashboard from '../views/sfa/dashboard/index.js';
import ViewTarget from './../views/sfa/view-target/ViewTarget';

function routes(loggedIn, user) {
  return [
    {
      path: '/login',
      name: 'Login',
      component: Login,
      redirect: user,
      pathTo: !user
        ? '/login'
        : user && !user.profile__c
        ? '/login'
        : user && user.profile__c === 'RM'
        ? '/sfa-dashboard'
        : '/dashboard',
      hideNavBar: true,
    },

    {
      redirect: loggedIn === false,
      path: '*',
      pathTo: '/login',
    },

    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard,
      hideNavBar: true,
      //  nodeRef: createRef(),
    },

    {
      path: '/category',
      name: 'Category',
      component: CategoryPage,
      hideNavBar: false,
      //  nodeRef: createRef(),
    },
    {
      path: '/farmermapping',
      name: 'Farmer Management',
      component: FarmerManagement,
      hideNavBar: false,
      //  nodeRef: createRef(),
    },
    {
      path: '/retailermapping',
      name: 'Reatailer Mapping',
      component: RetailerManagement,
      hideNavBar: false,
      //  nodeRef: createRef(),
    },
    {
      path: '/distributormapping',
      name: 'Reatailer Mapping',
      component: DistributorManagement,
      hideNavBar: false,
      //  nodeRef: createRef(),
    },
    {
      path: '/agriexpertmapping',
      name: 'Agri-Expert Mapping',
      component: AgriExpertManagement,
      hideNavBar: false,
      //  nodeRef: createRef(),
    },
    {
      path: '/farmervisit',
      name: 'Farmer Visits',
      component: FarmerVisit,
      hideNavBar: false,
      //  nodeRef: createRef(),
    },
    {
      path: '/retailervisit',
      name: 'Retailer Visits',
      component: RetailerVisit,
      hideNavBar: false,
      //  nodeRef: createRef(),
    },
    {
      path: '/distributorvisit',
      name: 'Distributor Visits',
      component: DistributorVisit,
      hideNavBar: false,
      //  nodeRef: createRef(),
    },
    {
      path: '/vancampaign',
      name: 'Van Campaign',
      component: VanCampaign,
      hideNavBar: false,
      //  nodeRef: createRef(),
    },
    {
      path: '/spotDemo',
      name: 'Spot Demo ',
      component: SpotDemo,
      hideNavBar: false,
      //  nodeRef: createRef(),
    },
    {
      path: '/normalDemo',
      name: 'Normal Demo ',
      component: NormalDemo,
      hideNavBar: false,
      //  nodeRef: createRef(),
    },
    {
      path: '/lpdActivity',
      name: 'LPD Activity ',
      component: LpdDemoActivity,
      hideNavBar: false,
      //  nodeRef: createRef(),
    },
    {
      path: '/farmermeet',
      name: 'Farmer Meet ',
      component: FarmerMeet,
      hideNavBar: false,
      //  nodeRef: createRef(),
    },
    {
      path: '/kvkvisit',
      name: 'KVK Visit',
      component: KvkVisit,
      hideNavBar: false,
      //  nodeRef: createRef(),
    },
    {
      path: '/krishimela',
      name: 'Krishi Mela',
      component: KrishiMela,
      hideNavBar: false,
      //  nodeRef: createRef(),
    },
    {
      path: '/feedbackreport',
      name: 'Feedback Report',
      component: FeedbackReport,
      hideNavBar: false,
      //  nodeRef: createRef(),
    },
    {
      path: '/testDashboard',
      name: 'Feedback Report',
      component: TestDashboard,
      hideNavBar: false,
      //  nodeRef: createRef(),
    },
    {
      path: '/mappingreport',
      name: 'Mapping Report',
      component: MappingManager,
      hideNavBar: false,
      //  nodeRef: createRef(),
    },
    {
      path: '/visitreport',
      name: 'Visit Report',
      component: VisitManager,
      hideNavBar: false,
      //  nodeRef: createRef(),
    },
    {
      path: '/eventreport',
      name: 'Visit Report',
      component: EventManager,
      hideNavBar: false,
      //  nodeRef: createRef(),
    },
    {
      path: '/orderreport',
      name: 'Order Report',
      component: OrderManagement,
      hideNavBar: false,
      //  nodeRef: createRef(),
    },
    {
      path: '/financialreport',
      name: 'Financial Report',
      component: FinanceManagement,
      hideNavBar: false,
    },
    {
      path: '/sfa-dashboard',
      name: 'SFA Dashboard',
      component: SfaDashboard,
      hideNavBar: true,
    },
    {
      path: '/view-target',
      name: 'View Target',
      component: ViewTarget,
      hideNavBar: true,
    },
    {
      path: '/consolidated',
      name: 'Consolidated',
      component: Consolidated,
      hideNavBar: false,
      //  nodeRef: createRef(),
    },
    {
      path: '/testing',
      name: 'Consolidated',
      component: Testing,
      hideNavBar: false,
      //  nodeRef: createRef(),
    },
    {
      path: '/agriexpertvisit',
      name: 'Agri-Expert Visit',
      component: AgriExpertVisit,
      hideNavBar: false,
      //  nodeRef: createRef(),
    },
    {
      path: '/usermanagement',
      name: 'User Management Visit',
      component: UserManagement,
      hideNavBar: false,
      //  nodeRef: createRef(),
    },
  ];
}

export default routes;
