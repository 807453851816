import {
  Typography,
  TextField,
  IconButton,
  Stack,
  Autocomplete,
  Grid,
  Box,
  Skeleton,
  Tooltip,
} from '@mui/material';
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import styled from '@emotion/styled';
import CircularProgress from '@mui/material/CircularProgress';

import eventBus from '../event-emitter/EventBus';

const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 16px;
  width: 100%;
  height: 100%;
  align-items: stretch;

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export default function AddTarget({
  onClose,
  data,
  getTargetActivityData,
  targetData,
  adding = true,
  viewOnly,
}) {
  const [open, setOpen] = React.useState(true);
  const [loading, setLoading] = React.useState(true);

  const [stateLoading, setStateLoading] = React.useState(false);
  const [districtLoading, setDistrictLoading] = React.useState(false);
  const [tehsilLoading, setTehsilLoading] = React.useState(false);
  const [staffLoading, setStaffLoading] = React.useState(false);
  const [activityTypeLoading, setActivityTypeLoading] = React.useState(false);
  const [productLoading, setProductLoading] = React.useState(false);
  const [cropLoading, setCropLoading] = React.useState(false);

  const [selectedFunction, setSelectedFunction] = useState();
  const [selectedYear, setSelectedYear] = useState(targetData.year__c || '');
  const [selectedQuarters, setSelectedQuarters] = useState([]);
  const [availableQuarters, setAvailableQuarters] = useState([]);
  const [notAvailableQuarters, setNotAvailableQuarters] = useState([]);
  const [finalQuartersMonthData, setFinalQuartersMonthData] = useState({});

  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState();
  const [selectedTerritory, setSelectedTerritory] = useState();

  const [districts, setDistricts] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState();

  const [tehsils, setTehsils] = useState([]);
  const [selectedTehsil, setSelectedTehsil] = useState();

  const [selectedStateEnabled, setSelectedStateEnabled] = useState(false);
  const [selectedDistrictEnabled, setSelectedDistrictEnabled] = useState(false);

  const [staffNames, setStaffNames] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState();
  const [staffProfile, setStaffProfile] = useState();
  const [staffId, setStaffId] = useState();
  const [employeeId, setEmployeeId] = useState();

  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedActivity, setSelectedActivity] = useState();

  const [activityTypes, setActivityTypes] = useState([]);
  const [products, setProducts] = useState([]);

  const [crops, setCrops] = useState([]);
  const [selectedCrop, setSelectedCrop] = useState();

  const [financialYearData, setFinancialYearData] = useState({});
  const [searchField, setSearchField] = useState('');

  const [quarterData, setQuarterData] = useState({});
  const [errors, setErrors] = useState({});

  const functions = [
    { value: 'Marketing', label: 'Marketing' },
    { value: 'Sales', label: 'Sales' },
  ];

  const quartersQbj = {
    1: ['April', 'May', 'June'],
    2: ['July', 'August', 'September'],
    3: ['October', 'November', 'December'],
    4: ['January', 'February', 'March'],
  };

  const quartersQbjKeys = {
    April_Target__c: 0,
    May_Target__c: 0,
    June_Target__c: 0,
    July_Target__c: 0,
    August_Target__c: 0,
    September_Target__c: 0,
    October_Target__c: 0,
    November_Target__c: 0,
    December_Target__c: 0,
    January_Target__c: 0,
    February_Target__c: 0,
    March_Target__c: 0,
  };

  const quarters = ['1', '2', '3', '4'];

  const matchingObject1 = {
    April: 'April_Target__c',
    May: 'May_Target__c',
    June: 'June_Target__c',
    July: 'July_Target__c',
    August: 'August_Target__c',
    September: 'September_Target__c',
    October: 'October_Target__c',
    November: 'November_Target__c',
    December: 'December_Target__c',
    January: 'January_Target__c',
    February: 'February_Target__c',
    March: 'March_Target__c',
  };

  const matchingObject2 = {
    April_Target__c: 'April',
    May_Target__c: 'May',
    June_Target__c: 'June',
    July_Target__c: 'July',
    August_Target__c: 'August',
    September_Target__c: 'September',
    October_Target__c: 'October',
    November_Target__c: 'November',
    December_Target__c: 'December',
    January_Target__c: 'January',
    February_Target__c: 'February',
    March_Target__c: 'March',
  };

  React.useEffect(() => {
    const user = JSON.parse(localStorage.getItem('storedUserData'));
    if (!user) {
      window.location.reload();
    }
    eventBus.emit('modalOpened', true);
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      getFinancialYearsWithRange();
      if (adding) {
        setLoading(false);
      }
      if (Object.keys(targetData)?.length > 0) {
        setSelectedState(targetData.state);
        setSelectedStateEnabled(true);
        setSelectedDistrict(targetData.district);
        setSelectedDistrictEnabled(true);
        setSelectedTehsil(targetData.tehsil);
        setSelectedTerritory([targetData.territory]);
        setSelectedStaff(targetData.staff);
        setStaffProfile(targetData.staff_profile__c);
        setStaffId(targetData.staff_id__c);
        setEmployeeId(targetData.employee_id__c || 'N/A');
        setSelectedFunction(targetData.targetFunction);
        setSelectedActivity(targetData.type_of_activity__c);
        setSelectedYear(targetData.year__c);
        setSelectedQuarters(
          typeof targetData.quater__c?.split(';') === 'string'
            ? [targetData.quater__c]
            : targetData.quater__c?.split(';')
        );
        setQuarterData((prev) => {
          const updatedData = { ...prev };

          for (let k in matchingObject1) {
            for (let ke in targetData) {
              if (matchingObject1[k].toLowerCase() === ke) {
                if (targetData[ke]) {
                  updatedData[matchingObject1[k]] = targetData[ke];
                }
              }
            }
          }

          return updatedData;
        });

        setSelectedProduct(targetData.product);
        setSelectedCrop(targetData.crop);
        setLoading(false);
      }
    };

    fetchData();
  }, [targetData]);

  React.useEffect(() => {
    if (Object.keys(targetData).length === 0) {
      availableQuartersValidationCheck();
    }
  }, [selectedStaff, selectedActivity, selectedYear]);

  function getFinancialYearsWithRange() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // getMonth() is zero-based

    let currentFinancialYearStart, currentFinancialYearEnd;

    // If current month is April or later, financial year starts in the current year
    if (currentMonth >= 4) {
      currentFinancialYearStart = new Date(currentYear, 3, 1); // April 1st of current year
      currentFinancialYearEnd = new Date(currentYear + 1, 2, 31); // March 31st of next year
    } else {
      currentFinancialYearStart = new Date(currentYear - 1, 3, 1); // April 1st of last year
      currentFinancialYearEnd = new Date(currentYear, 2, 31); // March 31st of current year
    }

    // Function to format date as YYYY-MM-DD
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero for month
      const day = String(date.getDate()).padStart(2, '0'); // Add leading zero for day
      return `${year}-${month}-${day}`;
    };

    // Function to calculate the previous financial year
    const getPreviousFinancialYear = (start, end) => {
      const prevStart = new Date(start.getFullYear() - 1, 3, 1); // Previous April 1st
      const prevEnd = new Date(end.getFullYear() - 1, 2, 31); // Previous March 31st
      return {
        financialYear: `${prevStart.getFullYear()}`,
        range: {
          start: formatDate(prevStart),
          end: formatDate(prevEnd),
        },
      };
    };

    // Current Financial Year
    const currentFinancialYear = {
      financialYear: `${currentFinancialYearStart.getFullYear()}`,
      range: {
        start: formatDate(currentFinancialYearStart),
        end: formatDate(currentFinancialYearEnd),
      },
    };

    // Previous Financial Year
    const previousFinancialYear = getPreviousFinancialYear(
      currentFinancialYearStart,
      currentFinancialYearEnd
    );

    // Financial Year Before Last
    const secondPreviousFinancialYear = getPreviousFinancialYear(
      new Date(previousFinancialYear.range.start),
      new Date(previousFinancialYear.range.end)
    );

    setFinancialYearData((prev) => {
      return {
        ...prev,
        currentFinancialYear,
        previousFinancialYear,
        secondPreviousFinancialYear,
      };
    });
  }

  const getStates = async (data = '') => {
    try {
      setStateLoading(true);
      const response = await window.Platform.database.getAllStates({
        pageNumber: 1,
        searchField: data,
      });
      setStates(response.data);
      if (response.hasError) {
        window.NotificationUtils.showError(
          'Not able to get states. Please try after some time.'
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setStateLoading(false);
    }
  };

  const getDistricts = async (data = null, search = '') => {
    try {
      setDistrictLoading(true);
      const response = await window.Platform.database.getAllDistricts({
        stateSfid: data?.sfid || selectedState.sfid,
        searchField: search,
        pageNumber: 1,
      });
      setDistricts(response.data);
      if (response.hasError) {
        window.NotificationUtils.showError(
          'Not able to get districts. Please try after some time.'
        );
      }
      setSelectedStateEnabled(true);
    } catch (error) {
      console.error(error);
      setSelectedStateEnabled(false);
    } finally {
      setDistrictLoading(false);
    }
  };

  const getTehsils = async (data = null, search = '') => {
    try {
      setTehsilLoading(true);
      const response = await window.Platform.database.getAllTehsils({
        districtSfid: data?.sfid || selectedDistrict.sfid,
        searchField: search,
        pageNumber: 1,
      });
      setTehsils(response.data);
      if (response.hasError) {
        window.NotificationUtils.showError(
          'Not able to get tehsils. Please try after some time.'
        );
      }
      setSelectedDistrictEnabled(true);
    } catch (error) {
      console.error(error);
      setSelectedDistrictEnabled(true);
    } finally {
      setTehsilLoading(false);
    }
  };

  const getTerritory = async (data) => {
    try {
      const response =
        await window.Platform.database.getTerritoryFromSubDistrict({
          subDistrictSfid: data?.sfid,
        });
      setSelectedTerritory(response.data);
      if (response.hasError) {
        window.NotificationUtils.showError(
          'Not able to get territory. Please try after some time.'
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const eventStaffTeams = async (data = '') => {
    try {
      setStaffLoading(true);
      const payload = {
        searchField: data,
      };
      const response = await window.Platform.database.eventStaffTeams(payload);
      setStaffNames(response.data);
      if (response.hasError) {
        window.NotificationUtils.showError(
          'Not able to get staff names. Please try after some time.'
        );
      }

      if (Object.keys(targetData).length > 0) {
        const staff = response.data.find(
          (item) => item.sfid === targetData.staff_id__c
        );
        setSelectedStaff(staff);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setStaffLoading(false);
    }
  };

  const getProducts = async (data = '') => {
    try {
      setProductLoading(true);
      const response = await window.Platform.database.getAllProducts({
        pageNumber: 1,
        searchField: data,
      });
      setProducts(response.data);
      if (response.hasError) {
        window.NotificationUtils.showError(
          'Not able to get products. Please try after some time.'
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setProductLoading(false);
    }
  };

  const activityTypeList = async (data = '') => {
    try {
      setActivityTypeLoading(true);
      const response = await window.Platform.database.activityTypeList({
        pageNumber: 1,
        searchField: data,
      });
      setActivityTypes(response.data);
      if (response.hasError) {
        window.NotificationUtils.showError(
          'Not able to get activity types list. Please try after some time.'
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setActivityTypeLoading(false);
    }
  };

  const getEventCropsById = async (productId = null, search = '') => {
    try {
      setCropLoading(true);
      const response = await window.Platform.database.getEventCropsById({
        product__c: productId || selectedProduct.product__c,
        pageNumber: 1,
        searchField: search,
      });
      setCrops(response.data);
      if (response.hasError) {
        window.NotificationUtils.showError(
          'Not able to get crops. Please try after some time.'
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setCropLoading(false);
    }
  };

  const handleStateChange = (data) => {
    setSelectedState(data);
    setSelectedDistrict(null);
    setSelectedTehsil(null);
    setSelectedTerritory(null);
    setSelectedStateEnabled(false);
    setSelectedDistrictEnabled(false);
    if (data) {
      getDistricts(data);
    } else {
      getStates();
    }
  };

  const handleDistrictChange = (data) => {
    setSelectedDistrict(data);
    setSelectedTehsil(null);
    setSelectedTerritory(null);
    setSelectedDistrictEnabled(false);
    if (data) {
      getTehsils(data);
    }
  };

  const handleTehsilChange = (data) => {
    setSelectedTehsil(data);
    setSelectedTerritory(null);
    if (data) {
      getTerritory(data);
    }
  };

  const handleStaffChange = (data) => {
    setSelectedStaff(data);
    setStaffProfile(data?.profile__c || '');
    setStaffId(data?.sfid || '');
    setEmployeeId(data?.employee_id__c || 'N/A');
  };

  const handleProductChange = (data) => {
    setSelectedProduct(data);
    getEventCropsById(data?.product__c);
  };

  const handleFunctionChange = (data) => setSelectedFunction(data);

  const handleQuarterDataChange = (value, quarterName) => {
    let qty = value.replace(/[^0-9.]/g, '');

    if ((qty.match(/\./g) || []).length > 1) {
      qty =
        qty.slice(0, qty.lastIndexOf('.')) +
        qty.slice(qty.lastIndexOf('.') + 1);
    }

    setQuarterData((prev) => {
      return { ...prev, [matchingObject1[quarterName]]: qty };
    });
  };

  const validateForm = (data) => {
    const errors = {};

    const fields = [
      'State__c',
      'District__c',
      'Tehsil__c',
      'Territory_Mapping__c',
      'Staff_Name__c',
      'Staff_Profile__c',
      // 'Staff_ID__c',
      'Function__c',
      'Type_of_Activity__c',
      'Quater__c',
      'Product__c',
      'Crop__c',
      'Year__c',
    ];

    fields.forEach((field) => {
      if (!data[field]?.trim()) {
        errors[field] = `${field
          .replace(/_/g, ' ')
          .replace(/__c$/, '')} is required`;
      }
    });

    for (let key in quartersQbjKeys) {
      if (key in data) {
        const quarterMonthValue = data[key];
        if (!quarterMonthValue) {
          const quarterKey = key.split('_')[0];
          errors[quarterKey] = `${quarterKey} target is required.`;
        }
      }
    }

    return errors;
  };

  const availableQuartersValidationCheck = () => {
    if (selectedStaff?.sfid && selectedActivity && selectedYear) {
      let updatedAvailableQuarters = ['1', '2', '3', '4'];
      let updatedNotAvailableQuarters = [];
      let quartersMapping = {};

      data.forEach((item) => {
        const staffId = item.staff_id__c;
        const typeOfActivity = item.type_of_activity__c;
        const year = item.year__c;

        if (
          staffId === selectedStaff.sfid &&
          typeOfActivity === selectedActivity &&
          year === selectedYear
        ) {
          const quarters = item?.quater__c ? item.quater__c.split(';') : [];

          quartersMapping = {
            1: {
              April_Target__c: item?.april_target__c,
              May_Target__c: item?.may_target__c,
              June_Target__c: item?.june_target__c,
            },
            2: {
              July_Target__c: item?.july_target__c,
              August_Target__c: item?.august_target__c,
              September_Target__c: item?.september_target__c,
            },
            3: {
              October_Target__c: item?.october_target__c,
              November_Target__c: item?.november_target__c,
              December_Target__c: item?.december_target__c,
            },
            4: {
              January_Target__c: item?.january_target__c,
              February_Target__c: item?.february_target__c,
              March_Target__c: item?.march_target__c,
            },
          };

          if (quarters.length > 0) {
            quarters.forEach((quarter) => {
              const index = updatedAvailableQuarters.indexOf(quarter);
              if (index !== -1) {
                updatedAvailableQuarters.splice(index, 1);
                updatedNotAvailableQuarters.push(quarter);
              }
            });
          }
        }
      });

      setAvailableQuarters(updatedAvailableQuarters);
      setNotAvailableQuarters(updatedNotAvailableQuarters);

      updatedNotAvailableQuarters.map((item) => {
        const quarterObj = quartersMapping[item];
        let alreadyAddedQuarter, alreadyAddedKey;
        for (let key in quarterObj) {
          const monthValue = quarterObj[key];
          if (monthValue === 0) {
            alreadyAddedQuarter = false;
            break;
          }
          alreadyAddedQuarter = true;
          alreadyAddedKey = item;
        }

        if (alreadyAddedQuarter) {
          setFinalQuartersMonthData((prev) => {
            return { ...prev, [alreadyAddedKey]: quarterObj };
          });
        }
      });
    } else {
      setAvailableQuarters(['1', '2', '3', '4']);
      setNotAvailableQuarters([]);
    }
  };

  const debounceTimer = React.useRef(null);

  const debouncedSearchField = (event, name) => {
    const conditionalObject = {
      State: () => setStateLoading(true),
      District: () => setDistrictLoading(true),
      Tehsil: () => setTehsilLoading(true),
      'Staff Name': () => setStaffLoading(true),
      'Type Of Activities': () => setActivityTypeLoading(true),
      Product: () => setProductLoading(true),
      Crop: () => setCropLoading(true),
    };
    conditionalObject[name]();

    const { value } = event.target;

    if (value.length !== 0) {
      if (value.length <= 3) {
        return;
      }
    }

    clearTimeout(debounceTimer.current);
    setSearchField(value);

    debounceTimer.current = setTimeout(() => {
      const conditionalObject = {
        State: () => getStates(value),
        District: () => getDistricts(null, value),
        Tehsil: () => getTehsils(null, value),
        'Staff Name': () => eventStaffTeams(value),
        'Type Of Activities': () => activityTypeList(value),
        Product: () => getProducts(value),
        Crop: () => getEventCropsById(null, value),
      };

      conditionalObject[name]();
    }, 1000);
  };

  const submitForm = async () => {
    const selectedQuarterData = {};

    if (selectedQuarters.includes(1)) {
      Object.assign(selectedQuarterData, quarter1);
    }

    if (selectedQuarters.includes(2)) {
      Object.assign(selectedQuarterData, quarter2);
    }

    if (selectedQuarters.includes(3)) {
      Object.assign(selectedQuarterData, quarter3);
    }

    if (selectedQuarters.includes(4)) {
      Object.assign(selectedQuarterData, quarter4);
    }

    const quarter1 = {
      April_Target__c: 0,
      May_Target__c: 0,
      June_Target__c: 0,
    };

    const quarter2 = {
      July_Target__c: 0,
      August_Target__c: 0,
      September_Target__c: 0,
    };

    const quarter3 = {
      October_Target__c: 0,
      November_Target__c: 0,
      December_Target__c: 0,
    };

    const quarter4 = {
      January_Target__c: 0,
      February_Target__c: 0,
      March_Target__c: 0,
    };

    const quartersMapping = {
      1: quarter1,
      2: quarter2,
      3: quarter3,
      4: quarter4,
    };

    const listOfQuarters = [];
    selectedQuarters.map((item) => {
      const quarter = quartersMapping[item];
      listOfQuarters.push(quarter);
    });

    const newObj = listOfQuarters.reduce(
      (acc, obj) => ({ ...acc, ...obj }),
      {}
    );

    for (let qKey in quarterData) {
      if (quarterData[qKey] === '0') {
        window.NotificationUtils.showError(
          'The month’s target cannot be set to zero.'
        );
        return;
      }
    }

    try {
      let payload = {
        ...newObj,
        ...selectedQuarterData,
        ...quarterData,
        Product__c: selectedProduct?.product__c,
        Product_Name__c: selectedProduct?.product_name__c,
        Crop__c: selectedCrop?.crop__c,
        Crop_Name__c: selectedCrop?.crop_name__c,
        State__c: selectedState?.sfid,
        State_Name__c: selectedState?.name__c,
        District__c: selectedDistrict?.sfid,
        District_Name__c: selectedDistrict?.name__c,
        Tehsil__c: selectedTehsil?.sfid,
        Tehsil_Name__c: selectedTehsil?.name__c,
        Staff_ID__c: selectedStaff?.sfid,
        Staff_Name__c: selectedStaff?.name__c,
        Staff_Profile__c: staffProfile,
        Quater__c: selectedQuarters?.join(';'),
        Type_of_Activity__c: selectedActivity,
        Function__c: selectedFunction?.value,
        Territory_Mapping__c: selectedTerritory?.[0]?.sfid,
        Year__c: selectedYear,
      };

      if (Object.keys(targetData).length > 0) {
        payload = {
          ...payload,
          isEdit: true,
          targetSfid: targetData.targetsfid,
        };
      }

      const errors = validateForm(payload);
      setErrors(errors);

      if (Object.keys(errors).length === 0) {
        setLoading(true);
        const response = await window.Platform.database.addEventTarget(payload);

        if (response.hasError) {
          window.NotificationUtils.showError(
            response.message ||
              'There is some error while adding the target. Please try again later.'
          );
          setLoading(false);
          return;
        }

        window.NotificationUtils.showSuccess(
          'Target has been added successfully.'
        );
        setLoading(false);
        onClose();
        getTargetActivityData();
      } else {
        window.NotificationUtils.showError('Please fill all required fields.');
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      window.NotificationUtils.showError(
        'There is some error. Please try after some time.'
      );
      setLoading(false);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Dialog
        PaperProps={{
          style: {
            minWidth: 950,
            maxWidth: 1500,
          },
        }}
        open={open}
      >
        <DialogContent
          style={{ position: 'relative', height: loading ? '100px' : 'auto' }}
        >
          {loading && (
            <CircularProgress
              style={{
                position: 'absolute',
                left: '50%',
                top: '30%',
              }}
              color='success'
            />
          )}
          {!loading && (
            <>
              <ModalHeaderContainer>
                <Typography
                  variant='h5'
                  sx={{
                    fontWeight: '600',
                    fontSize: '32px',
                    lineHeight: '48px',
                  }}
                >
                  {viewOnly
                    ? 'Target Details'
                    : adding
                    ? 'Add Target'
                    : 'Update Target'}
                </Typography>
                <IconButton disabled={loading} onClick={onClose}>
                  <CloseOutlinedIcon />
                </IconButton>
              </ModalHeaderContainer>
              <GridContainer>
                <Autocomplete
                  value={selectedState || null}
                  onChange={(event, newValue) => handleStateChange(newValue)}
                  options={states}
                  getOptionLabel={(option) => option.name__c}
                  isOptionEqualToValue={(option, value) =>
                    option.name__c === value
                  }
                  fullWidth
                  autoComplete='off'
                  onFocus={() => {
                    getStates();
                  }}
                  disabled={viewOnly}
                  loading={stateLoading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='State'
                      onChange={(event) => debouncedSearchField(event, 'State')}
                      slotProps={{
                        input: {
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {stateLoading ? (
                                <CircularProgress color='inherit' size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        },
                      }}
                      required
                      error={Boolean(errors.State__c)}
                    />
                  )}
                  filterOptions={(options, state) => {
                    return options.filter((option) =>
                      option.name__c.toLowerCase()
                    );
                  }}
                />
                <Box sx={{ position: 'relative' }}>
                  {!selectedStateEnabled && selectedState ? (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: -20,
                        left: 0,
                        height: '100%',
                        width: '100%',
                      }}
                    >
                      <Skeleton
                        variant='text'
                        width='100%'
                        height={90}
                        animation='wave'
                      />
                    </Box>
                  ) : (
                    <Tooltip
                      title={
                        !selectedStateEnabled
                          ? 'Please select a state before choosing a district.'
                          : ''
                      }
                    >
                      <Autocomplete
                        value={selectedDistrict || null}
                        onChange={(event, newValue) =>
                          handleDistrictChange(newValue)
                        }
                        options={districts}
                        getOptionLabel={(option) => option.name__c}
                        isOptionEqualToValue={(option, value) =>
                          option.name__c === value
                        }
                        fullWidth
                        disabled={viewOnly || !selectedStateEnabled}
                        autoComplete='off'
                        onFocus={() => {
                          getDistricts();
                        }}
                        loading={districtLoading}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label='District'
                            onChange={(event) =>
                              debouncedSearchField(event, 'District')
                            }
                            slotProps={{
                              input: {
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {districtLoading ? (
                                      <CircularProgress
                                        color='inherit'
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              },
                            }}
                            required
                            error={Boolean(errors.District__c)}
                          />
                        )}
                        filterOptions={(options, state) => {
                          return options.filter((option) =>
                            option.name__c.toLowerCase()
                          );
                        }}
                      />
                    </Tooltip>
                  )}
                </Box>
                <Box sx={{ position: 'relative' }}>
                  {!selectedDistrictEnabled && selectedDistrict ? (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: -20,
                        left: 0,
                        height: '100%',
                        width: '100%',
                      }}
                    >
                      <Skeleton
                        variant='text'
                        width='100%'
                        height={90}
                        animation='wave'
                      />
                    </Box>
                  ) : (
                    <Tooltip
                      title={
                        !selectedDistrictEnabled
                          ? 'Please select a district before choosing a tehsil.'
                          : ''
                      }
                    >
                      <Autocomplete
                        value={selectedTehsil || null}
                        onChange={(event, newValue) =>
                          handleTehsilChange(newValue)
                        }
                        options={tehsils}
                        getOptionLabel={(option) => option.name__c}
                        isOptionEqualToValue={(option, value) =>
                          option.name__c === value
                        }
                        fullWidth
                        disabled={viewOnly || !selectedDistrictEnabled}
                        autoComplete='off'
                        onFocus={() => {
                          getTehsils();
                        }}
                        loading={tehsilLoading}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label='Tehsil'
                            onChange={(event) =>
                              debouncedSearchField(event, 'Tehsil')
                            }
                            slotProps={{
                              input: {
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {tehsilLoading ? (
                                      <CircularProgress
                                        color='inherit'
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              },
                            }}
                            required
                            error={Boolean(errors.Tehsil__c)}
                          />
                        )}
                        filterOptions={(options, state) => {
                          return options.filter((option) =>
                            option.name__c.toLowerCase()
                          );
                        }}
                      />
                    </Tooltip>
                  )}
                </Box>

                <Tooltip
                  title={
                    !selectedTerritory?.[0]?.name
                      ? 'Please select a tehsil to see the respective territory.'
                      : ''
                  }
                >
                  <TextField
                    fullWidth
                    label='Territory'
                    InputLabelProps={{
                      shrink: selectedTerritory?.[0]?.name ? true : false,
                    }}
                    value={selectedTerritory?.[0]?.name || ''}
                    disabled
                    required
                    error={Boolean(errors.Territory_Mapping__c)}
                  />
                </Tooltip>

                <Autocomplete
                  value={selectedStaff || null}
                  onChange={(event, newValue) => handleStaffChange(newValue)}
                  options={staffNames}
                  getOptionLabel={(option) => option.name__c}
                  isOptionEqualToValue={(option, value) =>
                    option.name__c === value
                  }
                  fullWidth
                  autoComplete='off'
                  onFocus={() => {
                    eventStaffTeams();
                  }}
                  disabled={viewOnly}
                  loading={staffLoading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Staff Name'
                      onChange={(event) =>
                        debouncedSearchField(event, 'Staff Name')
                      }
                      slotProps={{
                        input: {
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {staffLoading ? (
                                <CircularProgress color='inherit' size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        },
                      }}
                      required
                      error={Boolean(errors.Staff_Name__c)}
                    />
                  )}
                  filterOptions={(options, state) => {
                    return options.filter((option) =>
                      option.name__c.toLowerCase()
                    );
                  }}
                />

                <Tooltip
                  title={
                    !staffProfile
                      ? 'Please select a staff name to see the respective staff profile.'
                      : ''
                  }
                >
                  <TextField
                    fullWidth
                    type='text'
                    label='Staff Profile'
                    InputLabelProps={{ shrink: staffProfile ? true : false }}
                    value={staffProfile || ''}
                    disabled
                    required
                    error={Boolean(errors.Staff_Profile__c)}
                  />
                </Tooltip>

                <Tooltip
                  title={
                    !staffId
                      ? 'Please select a staff name to see the respective staff ID.'
                      : ''
                  }
                >
                  <TextField
                    fullWidth
                    type='text'
                    label='Staff ID'
                    InputLabelProps={{ shrink: employeeId ? true : false }}
                    value={employeeId || ''}
                    disabled
                    // required
                    // error={Boolean(errors.Staff_ID__c)}
                  />
                </Tooltip>

                <Autocomplete
                  value={selectedFunction || null}
                  onChange={(event, newValue) => handleFunctionChange(newValue)}
                  options={functions}
                  getOptionLabel={(option) => option.value}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value
                  }
                  fullWidth
                  autoComplete='off'
                  disabled={viewOnly}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Function'
                      required
                      error={Boolean(errors.Function__c)}
                    />
                  )}
                  filterOptions={(options, state) => {
                    return options.filter((option) =>
                      option.value
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase())
                    );
                  }}
                />

                <Autocomplete
                  value={selectedActivity || null}
                  onChange={(event, newValue) => setSelectedActivity(newValue)}
                  options={activityTypes}
                  getOptionLabel={(option) => option}
                  isOptionEqualToValue={(option, value) => option === value}
                  fullWidth
                  autoComplete='off'
                  onFocus={() => {
                    activityTypeList();
                  }}
                  disabled={viewOnly}
                  loading={activityTypeLoading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Type of Activities'
                      slotProps={{
                        input: {
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {activityTypeLoading ? (
                                <CircularProgress color='inherit' size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        },
                      }}
                      required
                      error={Boolean(errors.Type_of_Activity__c)}
                    />
                  )}
                  filterOptions={(options, state) => {
                    return options.filter((option) =>
                      option
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase())
                    );
                  }}
                />

                <Autocomplete
                  value={selectedProduct || null}
                  onChange={(event, newValue) => handleProductChange(newValue)}
                  options={products}
                  getOptionLabel={(option) => option.product_name__c}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value
                  }
                  fullWidth
                  autoComplete='off'
                  disabled={viewOnly}
                  onFocus={() => {
                    getProducts();
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Product'
                      onChange={(event) =>
                        debouncedSearchField(event, 'Product')
                      }
                      loading={productLoading}
                      slotProps={{
                        input: {
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {productLoading ? (
                                <CircularProgress color='inherit' size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        },
                      }}
                      required
                      error={Boolean(errors.Product__c)}
                    />
                  )}
                  filterOptions={(options, state) => {
                    return options.filter((option) =>
                      option.name.toLowerCase()
                    );
                  }}
                />

                <Tooltip
                  title={
                    !selectedProduct
                      ? 'Please select a product to see the respective crops.'
                      : ''
                  }
                >
                  <Autocomplete
                    value={selectedCrop || null}
                    onChange={(event, newValue) => setSelectedCrop(newValue)}
                    options={crops}
                    getOptionLabel={(option) => option.crop_name__c}
                    isOptionEqualToValue={(option, value) =>
                      option.name === value
                    }
                    fullWidth
                    autoComplete='off'
                    disabled={viewOnly || !selectedProduct}
                    onFocus={() => {
                      getEventCropsById();
                    }}
                    loading={cropLoading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Crop'
                        onChange={(event) =>
                          debouncedSearchField(event, 'Crop')
                        }
                        slotProps={{
                          input: {
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {cropLoading ? (
                                  <CircularProgress color='inherit' size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          },
                        }}
                        required
                        error={Boolean(errors.Crop__c)}
                      />
                    )}
                    filterOptions={(options, state) => {
                      return options.filter((option) =>
                        option.name.toLowerCase()
                      );
                    }}
                  />
                </Tooltip>

                <Autocomplete
                  value={selectedYear}
                  onChange={(event, newValue) => setSelectedYear(newValue)}
                  options={[
                    financialYearData?.currentFinancialYear?.financialYear,
                  ]}
                  getOptionLabel={(option) => option}
                  isOptionEqualToValue={(option, value) => option === value}
                  fullWidth
                  disabled={viewOnly}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Year'
                      required
                      error={Boolean(errors.Year__c)}
                    />
                  )}
                />

                <Box>
                  <Autocomplete
                    multiple
                    options={quarters}
                    getOptionLabel={(option) => option.toString()}
                    getOptionDisabled={(option) =>
                      notAvailableQuarters.includes(option)
                    }
                    value={selectedQuarters || []}
                    onChange={(event, newValue) =>
                      setSelectedQuarters(newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Quarter'
                        fullWidth
                        required
                        error={Boolean(errors.Quater__c)}
                      />
                    )}
                    fullWidth
                    disabled={
                      viewOnly ||
                      !selectedStaff ||
                      !selectedActivity ||
                      !selectedYear
                    }
                  />

                  {notAvailableQuarters.length > 0 &&
                    selectedStaff &&
                    selectedActivity &&
                    selectedYear && (
                      <small
                        style={{
                          color: '#777',
                        }}
                      >
                        {`${
                          notAvailableQuarters.length > 1
                            ? `Quarters (${notAvailableQuarters.join(
                                ', '
                              )}) are not available because these were already used.`
                            : `Quarter (${notAvailableQuarters[0]}) is not available because it was already used.`
                        }`}
                      </small>
                    )}
                </Box>
              </GridContainer>
              {Object.keys(finalQuartersMonthData).length > 0 && (
                <Stack spacing={1} sx={{ paddingTop: '20px' }}>
                  <GridContainer container>
                    {quarters.map((item) => {
                      if (finalQuartersMonthData[item]) {
                        return Object.entries(
                          finalQuartersMonthData[item]
                        )?.map(([key, value]) => {
                          const label = matchingObject2[key];
                          return (
                            <Grid item xs={4} key={key}>
                              <TextField
                                label={label}
                                variant='outlined'
                                fullWidth
                                value={value}
                                disabled
                              />
                            </Grid>
                          );
                        });
                      }
                    })}
                  </GridContainer>
                </Stack>
              )}

              <Stack spacing={1} sx={{ paddingTop: '20px' }}>
                <GridContainer container>
                  {selectedQuarters.map((item) => {
                    return quartersQbj[item]?.map((quarter, index) => {
                      return (
                        <Grid item xs={4} key={index}>
                          <TextField
                            label={quarter}
                            variant='outlined'
                            fullWidth
                            value={quarterData[matchingObject1[quarter]] || ''}
                            onChange={(event) =>
                              handleQuarterDataChange(
                                event.target.value,
                                quarter
                              )
                            }
                            required
                            error={Boolean(errors[quarter])}
                            disabled={viewOnly}
                          />
                        </Grid>
                      );
                    });
                  })}
                </GridContainer>
              </Stack>
            </>
          )}
        </DialogContent>

        <DialogActions sx={{ padding: '15px 24px' }}>
          <Button
            variant='contained'
            color='secondary'
            onClick={onClose}
            disabled={loading}
          >
            Cancel
          </Button>
          {!viewOnly && (
            <Button
              variant='contained'
              color='primary'
              onClick={submitForm}
              disabled={loading}
            >
              {Object.keys(targetData).length === 0 ? 'Add' : 'Update'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
}
