import React, { useState, useEffect, useContext } from 'react';
import { AppBar, Toolbar, IconButton, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

import { AuthContext } from '../../contextAPI/ContextAPI';
import { useNavigate } from 'react-router-dom';
import { LOCAL_STORAGE_KEYS } from '../../../constants';

const isMobile = window.innerWidth < 900;

const icon = {
  cursor: 'pointer',
  color: '#79b835',
  margin: '4px',
  fontSize: '26px',
};

const btnContainer = { display: 'flex', marginLeft: 'auto' };

const flexContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
};

const container = { backgroundColor: '#F6F6F6', height: '62px' };

const CustomHeader = () => {
  const { auth } = useContext(AuthContext);

  const navigate = useNavigate();

  const [user, setUser] = useState(null);

  const [drawerOpen, setDrawerOpen] = useState(
    isMobile ? false : JSON.parse(localStorage.getItem('isDrawerOpen'))
  );

  useEffect(() => {
    localStorage.setItem('isDrawerOpen', drawerOpen);
  }, [drawerOpen]);

  useEffect(() => {
    let storedUser;
    try {
      storedUser = JSON.parse(
        window.localStorage.getItem(LOCAL_STORAGE_KEYS.STORED_USER_DATA)
      );
      setUser(storedUser);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const onClickLogout = async () => {
    try {
      const res = await window.Platform.database.logOut();

      if (!res?.hasError) {
        localStorage.clear();
        window.location.reload();
      }
      auth.onAuthUserChanged && auth.onAuthUserChanged();
    } catch (error) {
      window.NotificationUtils.showError('Something Went Wrong');
    }
  };

  const handleRoute = () => {
    navigate('/user-profile');
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <AppBar position='sticky' sx={container}>
      <Toolbar>
        <div style={flexContainer}>
          {isMobile && (
            <IconButton onClick={toggleDrawer}>
              <MenuIcon />
            </IconButton>
          )}
          <div style={btnContainer}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                transition: '0.7s all',
                '&:hover': {
                  cursor: 'pointer',
                  transform: 'scale(1.1)',
                },
              }}
              onClick={onClickLogout}
              title='Log Out'
            >
              <LogoutOutlinedIcon sx={icon} />
              <span style={{ color: '#000' }}>(Log Out)</span>
            </Box>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default CustomHeader;
