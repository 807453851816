import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Fab,
  DialogActions,
  Tabs,
  Tab,
  Autocomplete,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import Tooltip from '@mui/material/Tooltip';
import {
  Check as CheckIcon,
  Clear as ClearIcon,
  Close,
  CropSharp,
  Details,
} from '@mui/icons-material';
import { CloseOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import styled from 'styled-components';
import { ACTIVITY_STATUS } from '../../constants';
import moment from 'moment';
import OpaqueLoading from '../opaqueLoading/opaqueLoading';
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { AuthContext } from '../contextAPI/ContextAPI';

const isMobile = window.innerWidth < 900;

const TotalAmountTypography = styled(Typography)`
  position: absolute;

  bottom: 10px;

  right: 10px;

  font-size: ${isMobile ? '14px' : 'inherit'};

  bottom: ${isMobile ? '85px' : '70px'};

  right: ${isMobile ? '15px' : '70px'};
`;
const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
`;
const TableContainer = styled.div`
  // height: calc(
  //   100vh - ${isMobile ? '106px - 20px - 82.23px' : '98px - 43px - 12.77px'}
  // );
  width: ${isMobile ? '100%' : '98%'};
  border: solid 1px lightGrey;
  border-radius: 8px;
  margin-left: ${isMobile ? '0px' : '10px'};
`;
const InputFieldContainer = styled.div`
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  label {
    margin-bottom: 0px;
  }
`;
const TabsContainer = styled.div`
  ${isMobile &&
  `
    height: calc(100vh - 136px - 20px - 43.77px);
  `}
`;
const StyledTabs = styled(Tabs)`
  border-bottom: solid 1px lightGrey;
`;

const ModalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const FirstButton = styled(Button)`
  margin-right: 10px !important;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
`;

const StyledDialog = styled(Dialog)`
  position: relative;
`;

const FabStyle = styled(Fab)`
  && {
    z-index: 1;
  }
`;

const DataGridContainer = styled.div`
  width: 100%;
`;
const DataGridContainer1 = styled.div`
  width: 100%;
  margin-bottom: ${!isMobile && '30px'};
  padding: 8px;

  //   height: 500px;
`;

const NoteText = styled(Typography)`
  margin-top: 10px !important;
`;

const Input = styled.input`
  display: none;
`;

const OrderDetailsModal = ({
  onClose,
  remarks,
  data,
  id,
  distributorId,
  fetchData,
  selectedTab,
}) => {
  console.log('DATA', data);
  const [tabValue, setTabValue] = useState(0);
  const { auth, setAuth } = useContext(AuthContext);
  console.log('DATAAUTH', auth.user.plant_master__c);

  const [details, setDetails] = useState(
    data?.orderDetails?.orders?.map((obj, index) => ({ ...obj, id: index + 1 }))
  );

  const [plantCode, setPlantCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState();
  const [dynamicFields, setDynamicFields] = useState(
    data?.orderDetails?.orders?.map((obj, index) => ({
      pricebookentryid: obj.pricebookentryid,
      name: obj.product_name__c,
      quantity: obj.quantity,
      quantityCv: obj.quantity_in_cv__c,
      conversionValue: obj.product_conversion_rate__c,
      unitprice: obj.unitprice,
      totalprice: obj.totalprice,
      oid: obj.orderitemnumber,
      product2id: obj.product2id,
      id: index + 1,
    }))
  );
  const [deletedField, setDeletedFields] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [remark, setRemrak] = useState(remarks);
  const [form, setForm] = useState({
    product_name__c: null,
    quantity_in_cv__c: null,
    product_conversion_rate__c: null,
    unitprice: null,
    quantity: null,
    totalAmount: null,
    pricebookentryid: null,
    product2id: null,
    material_number__c: null,
  });
  const [selectedProduct, setSelectedProduct] = useState(null);
  const handleChangeTab = (event, newValue) => {
    console.log('newValue: ', newValue);
    setTabValue(newValue);
  };
  const orderHerokuId = data?.orderDetails?.orders[0]?.order__heroku_id__c;
  console.log('orderHerokuId', orderHerokuId);
  useEffect(() => {
    fetchPlantCode(distributorId);
  }, []);

  useEffect(() => {
    if (tabValue === 1) {
      fetchProduct(plantCode, distributorId);
    }
  }, [tabValue]);

  const getItemColumns = () => {
    let result = [
      {
        field: 'id',
        headerName: 'Item Number',
        sortable: false,
        width: 150,
        valueGetter: (params) => params.row?.orderitemnumber || 'N/A',
        renderCell: (params) => {
          let val = params.row?.orderitemnumber || 'N/A';
          return <Tooltip title={val}>{val}</Tooltip>;
        },
        // editable: true,
      },

      {
        field: 'product_name__c',
        headerName: 'Product Name',
        sortable: false,
        width: 250,
        // editable: true,
      },
      {
        field: 'listprice',
        headerName: 'List Price',
        sortable: false,
        width: 150,
        valueGetter: (params) => params.row?.listprice || 'N/A',
        renderCell: (params) => {
          // console.log(params.row?.container?.id)
          const value =
            (params.row?.listprice && params.row?.listprice.toFixed(2)) ||
            'N/A';
          if (typeof value === 'number' || !isNaN(parseFloat(value))) {
            const formattedValue = parseFloat(value).toLocaleString('en-IN');
            return <Tooltip title={formattedValue}>{formattedValue}</Tooltip>;
          } else {
            return <Tooltip title='N/A'>N/A</Tooltip>;
          }
        },
        // editable: true,
      },
      {
        field: 'quantity',
        headerName: 'Quantity',
        sortable: false,
        width: 150,
        // editable: true,
      },
      {
        field: 'quantity_in_cv__c',
        headerName: 'Quantity(cv)',
        sortable: false,
        width: 150,
        // editable: true,
      },
      {
        field: 'unitprice',
        headerName: 'Unit Price',
        sortable: false,
        width: 150,
        valueGetter: (params) => params.row?.unitprice || 'N/A',
        renderCell: (params) => {
          // console.log(params.row?.container?.id)
          const value =
            (params.row?.unitprice && params.row?.unitprice.toFixed(2)) ||
            'N/A';
          if (typeof value === 'number' || !isNaN(parseFloat(value))) {
            const formattedValue = parseFloat(value).toLocaleString('en-IN');
            return <Tooltip title={formattedValue}>{formattedValue}</Tooltip>;
          } else {
            return <Tooltip title='N/A'>N/A</Tooltip>;
          }
        },
      },
      // editable: true,

      {
        field: 'totalprice',
        headerName: 'Total Price',
        sortable: false,
        width: 150,
        valueGetter: (params) => params.row?.totalprice || 'N/A',
        renderCell: (params) => {
          // console.log(params.row?.container?.id)
          const value =
            (params.row?.totalprice && params.row?.totalprice.toFixed(2)) ||
            'N/A';
          if (typeof value === 'number' || !isNaN(parseFloat(value))) {
            const formattedValue = parseFloat(value).toLocaleString('en-IN');
            return <Tooltip title={formattedValue}>{formattedValue}</Tooltip>;
          } else {
            return <Tooltip title='N/A'>N/A</Tooltip>;
          }
        },
        // editable: true,
      },
      {
        field: 'status__c',
        headerName: 'Status',
        sortable: false,
        width: 150,
        // editable: true,
      },
    ];

    return result;
  };

  const getItemColumns1 = () => {
    let result = [
      {
        field: 'product_name__c',
        headerName: 'Product Name',
        sortable: false,
        width: 250,
        valueGetter: (params) => params.row?.product_name__c || 'N/A',
        renderCell: (params) => {
          console.log('Paramsss', params.row);
          // console.log(params.row?.container?.id)
          const value =
            (params.row?.product_name__c && params.row?.product_name__c) ||
            'N/A';

          return <Tooltip title='N/A'>{value}</Tooltip>;
        },
        // editable: true,
      },
      {
        field: 'quantity(cv)',
        headerName: 'Quantity (CV)',
        alignHeader: 'center',
        sortable: false,
        width: 150,
        valueGetter: (params) => params.row?.quantity_in_cv__c || 'N/A',
        renderCell: (params) => {
          // console.log(params.row?.container?.id)
          const value =
            (params.row?.quantity_in_cv__c &&
              (+params.row?.quantity_in_cv__c).toFixed(2)) ||
            'N/A';
          return <Tooltip title='N/A'>{value}</Tooltip>;
        },
        // editable: true,
      },
      {
        field: 'quantity',
        headerName: 'Quantity',
        sortable: false,
        width: 150,
        valueGetter: (params) => params.row?.quantity || 'N/A',
        renderCell: (params) => {
          // console.log(params.row?.container?.id)
          const value =
            (params.row?.quantity && (+params.row?.quantity).toFixed(2)) ||
            'N/A';
          return <Tooltip title='N/A'>{value}</Tooltip>;
          // editable: true,
        },
      },
      {
        field: 'unitPrice',
        headerName: 'Unit Price',
        sortable: false,
        width: 150,
        valueGetter: (params) => params.row?.unitprice || 'N/A',
        renderCell: (params) => {
          // console.log(params.row?.container?.id)
          const value =
            (params.row?.unitprice && params.row?.unitprice.toFixed(2)) ||
            'N/A';
          if (typeof value === 'number' || !isNaN(parseFloat(value))) {
            const formattedValue = parseFloat(value).toLocaleString('en-IN');
            return <Tooltip title={formattedValue}>{formattedValue}</Tooltip>;
          } else {
            return <Tooltip title='N/A'>N/A</Tooltip>;
          }
          // editable: true,
        },
      },
      {
        field: 'totalPrice',
        headerName: 'Total Price',
        sortable: false,
        width: 150,
        valueGetter: (params) =>
          calculateTotalPrice(params.row.quantity, params.row.unitprice) ||
          'N/A',
        renderCell: (params) => {
          const value =
            (params.row?.quantity &&
              calculateTotalPrice(params.row.quantity, params.row.unitprice)) ||
            'N/A';

          if (typeof value === 'number' || !isNaN(parseFloat(value))) {
            const formattedValue = parseFloat(value).toLocaleString('en-IN');
            return <Tooltip title={formattedValue}>{formattedValue}</Tooltip>;
          } else {
            return <Tooltip title='N/A'>N/A</Tooltip>;
          }
          // editable: true,
        },
        // editable: true,
      },

      {
        field: 'Actions',
        headerName: 'Actions',
        width: 80,
        // height: 10,
        headerAlign: 'center',
        sortable: false,
        filterable: false,
        // editable: true,
        renderCell: (params) => {
          let name = params.row.total || 'N/A';

          return (
            <>
              <IconButton
                onClick={() => editProduct(params.row.id)}
                sx={{ color: 'red' }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() => deleteproduct(params.row.id)}
                sx={{ color: 'red' }}
              >
                <DeleteIcon />
              </IconButton>
            </>
          );
        },
      },
    ];

    return result;
  };

  const fetchProduct = async (plantcode, dId) => {
    try {
      setLoading(true);
      console.log('PLANTCODE', plantcode[0].plant_code__c);
      let results = await window.Platform.database.getProductForOrder({
        distributorSfid: dId,
        plantCodeSfid: plantcode[0].plantcodesfid,
        forDepo: true,
      });
      console.log('PRODUCT RESULTS: ', results);
      setProduct(results.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const fetchPlantCode = async (data) => {
    try {
      setLoading(true);

      let results = await window.Platform.database.getDepotsForOrder({
        distributorSfid: data,
      });
      // console.log("RESULTSSSSSSSS",results.data[0].sfid)
      const plantCode = results?.data?.filter(
        (data) => data.plantcodesfid === auth.user.plant_master__c
      );
      console.log('plantCode', plantCode);
      setPlantCode(plantCode);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  // a0QHy00000JyYWjMAN

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport
          csvOptions={{
            fileName: 'product-list',
          }}
        />
      </GridToolbarContainer>
    );
  }

  const calculateTotalPrice = (quantity, unitPrice) => {
    const totalPrice = quantity * unitPrice;

    return isNaN(totalPrice) ? '' : totalPrice.toFixed(2);
  };

  const handleQuantityChange = (index, value) => {
    console.log('VALUE', value);

    const sanitizedValue = value.replace(/[^0-9.]/g, '');
    console.log('TESTSANITIZEDVAL', sanitizedValue);

    // const numericValue = parseFloat(sanitizedValue);
    const numericValue = sanitizedValue === '' ? 0 : sanitizedValue;
    console.log('TESTSANITIZEDVAL', numericValue);

    // Check if the numeric value is valid (not NaN)
    if (!isNaN(numericValue)) {
      setForm((prevForm) => ({
        ...prevForm,
        quantity: (numericValue * prevForm.product_conversion_rate__c).toFixed(
          2
        ),
        totalAmount: numericValue * prevForm.unitprice,
        quantity_in_cv__c: numericValue,
      }));
    } else {
      setForm((prevForm) => ({
        ...prevForm,
        quantity: 0,
        totalAmount: 0,
        quantity_in_cv__c: '',
      }));
    }
  };

  const handleAddFields = () => {
    const lastItem = dynamicFields[dynamicFields.length - 1];

    if (isLastItemComplete) {
      setDynamicFields((prevDetails) => [
        ...prevDetails,
        {
          name: '',
          quantity: '',
          unitprice: 0,
          totalprice: 0,
          id: prevDetails.length + 1,
          pricebookentryid: '',
        },
      ]);
      setHasChanges(true);
    } else if (!lastItem) {
      setDynamicFields(() => [
        {
          name: '',
          quantity: '',
          unitprice: 0,
          totalprice: 0,
          id: 1,
          pricebookentryid: '',
        },
      ]);
      setHasChanges(true);
    }
  };

  console.log('DynamicFields', dynamicFields);

  const handleTextFieldChange = (index, quantity, unit) => {
    const price = calculateTotalPrice(quantity, unit);
    setDynamicFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index].totalprice = price;
      return updatedFields;
    });
  };
  console.log('DYNAMICFIELDS', dynamicFields);
  const handleSelectChange = (index, value) => {
    console.log('SELECTEDVAL', value);
    // console.log("ascss",unitPrice)
    setForm((prevForm) => ({
      ...prevForm,
      product_name__c: value.name,
      material_number__c: value.material_number__c,
      pricebookentryid: value.pricebookentryid,
      product_conversion_rate__c: value.product_conversion_rate__c,
      unitprice: value.unitprice,
      product2id: value.sfid,
    }));
    setSelectedProduct(value);
  };
  const isLastItemComplete = () => {
    const lastItem = dynamicFields?.[dynamicFields?.length - 1];

    if (lastItem) {
      const isProductComplete =
        lastItem.product2id !== '' && lastItem.name !== '';
      const isQuantityComplete = lastItem.quantity !== '';

      return isProductComplete && isQuantityComplete;
    } else {
      return true;
    }
  };
  const handleDeleteField = (index) => {
    console.log('DATAAAA', data?.orderDetails);
    setDynamicFields((prevDetails) => {
      const updatedDetails = [...prevDetails];
      const deletedItem = updatedDetails.splice(index, 1)[0]; // Remove the item and store it
      console.log('DELETE', deletedItem);
      setDeletedFields((prevDeletedFields) => [
        ...prevDeletedFields,
        deletedItem,
      ]); // Store in another array
      setHasChanges(true);
      return updatedDetails;
    });
  };
  console.log('NO OF DELTED FIELD', deletedField?.length);
  const remarkOnChange = (value) => {
    setHasChanges(true);
    console.log('REMOVE', value);
    setRemrak(value);
  };
  const renderContent1 = () => {
    return (
      <>
        {/* <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",padding:"15px"}}>
    <Button sx={{margin:"7px"}} variant="text" color="primary" disabled={!isLastItemComplete()||loading} startIcon={<AddCircleIcon/>} onClick={handleAddFields}>
             Product
          </Button>
          <TextField label="Remarks" value={remark} type="text" onChange={(e)=>remarkOnChange(e.target.value)}/>
          </div> */}
        {/* <DataGridContainer1 style={{ maxHeight:isMobile?"370px": "390px", minHeight:isMobile?"370px": "390px", overflowY: "auto" }}>
      
        {/* {renderTextFields(data?.orderDetails?.length)} */}

        <Stack spacing={2} direction={isMobile ? 'column' : 'row'} padding={2}>
          <Autocomplete
            options={product}
            // getOptionLabel={}
            getOptionLabel={(option) => `${option?.name} `}
            // getOptionValue={(option) => option?.schoolId || ''}
            style={{ width: '40%' }}
            value={selectedProduct}
            // disabled={loading
            // || !addNewMode || props.isManager
            // }
            noOptionsText={loading ? 'Loading...' : 'No option'}
            onChange={(event, value) => {
              if (value) {
                console.log('TEST1212', value);
                handleSelectChange('partyId', value);
              } else {
                setSelectedProduct(null);

                setForm({
                  product_name__c: null,
                  quantity_in_cv__c: 0,
                  product_conversion_rate__c: 0,
                  unitprice: 0,
                  quantity: 0,
                  totalAmount: 0,
                  pricebookentryid: null,
                  product2id: null,
                });
              }
              // setSelectParty(value)
              //  updateDataGrid(value);

              // if(selectedCity.warehouseCountry === 'India' && warehouseData.attributes.warehouseCountry === 'China')
              // {
              //     setDisableTransfer(false)
              // }
              // else
              // setDisableTransfer(true)
            }}
            onInputChange={(e, value) => {
              // Check if the user's input matches any option
              // const matchingOption = party.find((option) => option.schoolName === value.schoolName);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                // disabled={
                //   loading
                // || !addNewMode || props.isManager || props.isDeleted
                // }
                label={'Select Product'}
                required
                variant='outlined'
              />
            )}
          />
          {/* <FormControl variant="outlined" style={{ width: isMobile?"100%":"30%" }}>
  <InputLabel id={`select-label-${1}`}>Product</InputLabel>
  <Select
    labelId={`Product-${1}`}
    label="Product"
    // value={detail?.name}
    onChange={(e) => handleSelectChange(1, e.target.value)}
  >
    {product?.map((option, optionIndex) => (
<MenuItem key={optionIndex} value={option.name}>
{option.name}
</MenuItem>
))}
  </Select>
</FormControl> */}
          <TextField
            label={`Quantity (CV)`}
            variant='outlined'
            sx={{ width: '20%' }}
            value={form?.quantity_in_cv__c || ''}
            InputProps={{
              inputProps: {
                pattern: '[0-9.]*',
              },
            }}
            type='text'
            disabled={loading || !selectedProduct}
            onChange={(e) => handleQuantityChange(1, e.target.value)}
          />
          <TextField
            label={`Quantity`}
            variant='outlined'
            sx={{ width: '20%' }}
            value={form?.quantity}
            InputProps={{
              inputProps: {
                pattern: '[0-9]*',
              },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            disabled
            onChange={(e) => handleQuantityChange(1, e.target.value)}
          />
          <TextField
            label={`Unit Price`}
            variant='outlined'
            sx={{ width: '20%' }}
            value={form?.unitprice}
            InputLabelProps={{
              shrink: true,
            }}
            disabled
            // onChange={(e) => handleTextFieldChange(1, e)}
          />
          <TextField
            label={`Total Price`}
            variant='outlined'
            sx={{ width: '20%' }}
            value={calculateTotalPrice(form.quantity, form.unitprice)}
            disabled
            // onChange={(e) => handleTextFieldChange(index, e)}
          />

          <Button
            color='primary'
            aria-label='add'
            variant='text'
            size='small'
            sx={{ borderRadius: '20px' }}
            disabled={loading || !selectedProduct || !form.quantity}
            onClick={() => addProduct()}
          >
            <AddCircleIcon fontSize='large' />
          </Button>
        </Stack>
        <DataGridContainer1>
          <DataGrid
            className='payrollGrid'
            sx={{ height: isMobile ? '555px' : '270px' }}
            checkboxSelection={false}
            rows={details || []}
            columns={getItemColumns1()}
            rowHeight={32}
            disableSelectionOnClick
            disableRowSelectionOnClick
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </DataGridContainer1>
      </>
    );
  };

  const addProduct = () => {
    let index = details.findIndex((el) => {
      return el.product2id === form.product2id;
    });

    if (index < 0) {
      let pushedItem = { id: details.length + 1, ...form };
      setDetails([...details, pushedItem]);
    } else {
      let tempArray = [...details];
      tempArray[index] = { id: index + 1, ...form };
      setDetails([...tempArray]);
    }
    setForm({
      product_name__c: null,
      quantity_in_cv__c: 0,
      conversionValue: 0,
      unitprice: 0,
      quantity: 0,
      totalAmount: 0,
      pricebookentryid: null,
      product2id: null,
    });
    setSelectedProduct(null);
    setHasChanges(true);
  };

  const renderContent = (params) => {
    return (
      <>
        <DataGridContainer>
          <DataGrid
            className='payrollGrid'
            sx={{ height: isMobile ? '555px' : '470px' }}
            checkboxSelection={false}
            rows={details || []}
            columns={getItemColumns()}
            rowHeight={32}
            disableSelectionOnClick
            disableRowSelectionOnClick
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </DataGridContainer>
      </>
    );
  };

  const saveChanges = async () => {
    try {
      setLoading(true);

      const totalAmount = details.reduce((acc, obj) => {
        return acc + (obj.totalprice || 0); // If 'age' is undefined or null, use 0
      }, 0);

      const mappedData = details.map(
        ({ quantity, material_number__c, unitprice, product2id, ...rest }) => ({
          ...rest, // Spread the rest of the properties unchanged
          qty: quantity, // Rename and parse quantity if needed
          materialNumber: material_number__c, // Rename material_number__c
          price: unitprice, // Rename and parse unitprice if needed
          productId: product2id,
        })
      );

      const payload = {
        // "distributorSfid": data?.orderDetails?.distributorsfid,
        // "plantCodeSfid": data?.orderDetails?.plantcodesfid,
        totalAmount: totalAmount,
        // "sfaSfid": null,
        // "fromDms": false,
        remarks__c: remark,
        orderHerokuId: orderHerokuId,
        orders: mappedData,
      };
      console.log('Checking PAYLOAD', payload);
      // console.log('Filtered array',filteredArray)
      // console.log('Filtered array1',filteredArray1)

      // const herokuId=localStorage.getItem('hId')
      let results = await window.Platform.database.updateOrderDetails(payload);
      if (results.hasError === false) {
        window.NotificationUtils.showSuccess(
          'Order Update Success.Please wait for 2 minutes to view updated order !'
        );
        onClose();
        fetchData();
      } else {
        window.NotificationUtils.showError('Error Please Wait and try again');
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      window.NotificationUtils.showError('Error Please Wait and try again');
      setLoading(false);
    }
  };

  const deleteproduct = (id) => {
    let res = details.filter((el) => {
      return el.id != id;
    });
    setDetails(res);
    setHasChanges(true);
  };

  const editProduct = (id) => {
    try {
      let toBeEditDetail = details.filter((el) => {
        return el.id == id;
      });
      console.log('checking edit product', toBeEditDetail);
      if (!toBeEditDetail.length) {
        window.NotificationUtils.showError('Order details not found.');
      }
      const {
        material_number__c = '',
        product2id = '',
        pricebookentryid = '',
        totalprice = '',
        quantity = '',
        unitprice = '',
        product_conversion_rate__c = '',
        quantity_in_cv__c = '',
        product_name__c = '',
      } = toBeEditDetail[0];
      setSelectedProduct({
        product_conversion_rate__c,
        unitprice,
        pricebookentryid,
        product2id,
        material_number__c,
        name: product_name__c,
        pricebook2id: '',
        sfid: '',
      });
      setForm({
        product_name__c,
        quantity_in_cv__c,
        product_conversion_rate__c,
        unitprice,
        quantity,
        totalAmount: totalprice,
        pricebookentryid,
        product2id,
        material_number__c,
      });

      setHasChanges(true);
    } catch (error) {
      console.error(error);
      window.NotificationUtils.showError(error.message);
    }
  };

  return (
    <Dialog
      open={true}
      onClose={onClose}
      maxWidth='lg'
      PaperProps={{
        style: {
          padding: '10px',
          borderRadius: '10px',
          minWidth: isMobile ? 350 : 1000,
          width: isMobile ? 350 : 1000,
        },
      }}
    >
      {loading && <OpaqueLoading />}
      <ModalHeaderContainer style={{ marginBottom: 2, paddingBottom: 3 }}>
        <Typography variant={isMobile ? 'h6' : 'h5'}>
          {/* {items?.customerName} */}
          Order
          <Tooltip title='Order Id'>{'(' + id + ')'}</Tooltip>
        </Typography>
        <IconButton onClick={onClose}>
          <CloseOutlined />
        </IconButton>
      </ModalHeaderContainer>
      <TableContainer>
        <TabsContainer>
          <StyledTabs
            variant='fullWidth'
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor='primary'
            textColor='primary'
          >
            <Tab label='View Details' />
            {selectedTab === 0 && <Tab label='Update Details' />}
          </StyledTabs>
          {tabValue === 0 ? renderContent() : renderContent1()}
        </TabsContainer>
        {/* <Typography variant={isMobile && "body2"} style={{ padding: "10px",marginLeft:"10px", display: isMobile ?"-ms-grid":"flex", gap: "10px", maxHeight:"500px" }}><strong>Remark:</strong> {items?.remark}</Typography> */}

        {tabValue === 1 && (
          <div
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
              padding: 2,
              gap: 30,
            }}
          >
            <TextField
              label='Remarks'
              sx={{ width: '40%' }}
              value={remark}
              type='text'
              onChange={(e) => remarkOnChange(e.target.value)}
            />
            <Button
              variant='contained'
              disabled={!hasChanges || loading || !details.length}
              onClick={() => saveChanges()}
              sx={{ marginBottom: 2 }}
            >
              Save Changes
            </Button>
          </div>
        )}
      </TableContainer>
    </Dialog>
  );
};

export default OrderDetailsModal;
